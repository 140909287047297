import { computed } from "vue";

import { useSetupStore } from "@/store/setup-store";

import { useTranslate } from "./use-translate";

export function useLanguages() {
  const { t, locale } = useTranslate();
  const setupStore = useSetupStore();

  /**
   * array of customer languages currently supported by the manager
   */
  const customerLanguages = computed(() => {
    return setupStore.availableLanguages;
  });

  const sortedCustomerLanguages = computed(() => {
    return [...customerLanguages.value].sort();
  });

  /**
   * array of user interface languages currently supported by the manager
   */
  const userInterfaceLanguages = computed(() => {
    return setupStore.managerLanguages;
  });

  function getLanguagesForSelectInput(
    languages: string[]
  ): { id: string; name: string }[] {
    return languages.map((l) => ({
      id: l,
      name: t(`languages.${l.toLowerCase()}`),
    }));
  }

  return {
    customerLanguages,
    userInterfaceLanguages,
    customerLanguagesForSelectInput: computed(() =>
      getLanguagesForSelectInput(customerLanguages.value)
    ),
    userInterfaceLanguagesForSelectInput: computed(() =>
      getLanguagesForSelectInput(userInterfaceLanguages.value)
    ),
    getSortedLanguages(desiredOrder: string[] = [locale.value]): string[] {
      const finalArray = [
        ...(desiredOrder || []).map((l) => l?.toLowerCase().trim()),
      ];
      sortedCustomerLanguages.value?.forEach((lang) => {
        if (!desiredOrder.includes(lang.toLowerCase())) {
          finalArray.push(lang.toLowerCase());
        }
      });

      return finalArray.filter((l) => !!l);
    },
  };
}
