import BaseProxy from "@/api/base-proxy";
import type {
  IUserCreatePayload,
  IUserDetail,
  IUserList,
  IUserUpdateGeneralPayload,
} from "@/api/types/user-types";

class UserService extends BaseProxy<
  IUserList,
  IUserDetail,
  IUserCreatePayload,
  IUserDetail,
  Partial<IUserUpdateGeneralPayload>,
  IUserDetail
> {
  constructor() {
    super("user");
  }
}

export const userService = new UserService();
