/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Plugins
// Types
import type { App } from "vue";

export function installPlugins(app: App, ...initFn: ((app: App) => void)[]) {
  initFn.forEach((fn) => fn(app));
}
