import { routeNames } from "@/router/route-names";
import type { RouteMetaConfig } from "@/types/router";
import { buildModalRoute } from "@/utils/modal-utils";

export const routesLocationSettings: RouteMetaConfig[] = [
  {
    path: "/location-settings",
    name: routeNames.locationSettingsOverview,
    component: () => import("../locations/LocationListView.vue"),
    props: { isStorefront: true },
    meta: {
      requiresAuth: true,
      permission: "location_storefronts.view",
    },
  },
  {
    path: "/location-settings/:id(\\d+)",
    name: routeNames.locationSettingsDetails,
    component: () => import("./LocationSettingsDetailView.vue"),
    meta: {
      requiresAuth: true,
      permission: "location_storefronts.view",
    },
    children: [
      buildModalRoute(
        {
          path: "edit/general",
          name: routeNames.locationSettingsEditGeneral,
          meta: {
            requiresAuth: true,
            permission: "location_storefronts.update",
          },
          component: () => import("./LocationSettingsEditGeneralFormView.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          path: "edit/billing",
          name: routeNames.locationSettingsEditBilling,
          meta: {
            requiresAuth: true,
            permission: "location_storefronts.update",
          },
          component: () => import("./LocationSettingsEditBillingFormView.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          path: "edit/categories",
          name: routeNames.locationSettingsEditCategories,
          meta: {
            requiresAuth: true,
            permission: "location_storefronts.update",
          },
          component: () =>
            import("./LocationSettingsEditCategoriesFormView.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          path: "edit/consents",
          name: routeNames.locationSettingsEditConsents,
          meta: {
            requiresAuth: true,
            permission: "location_storefronts.update",
          },
          component: () => import("./LocationSettingsEditConsentsFormView.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          path: "edit/customer-portal",
          name: routeNames.locationSettingsEditCustomerPortal,
          meta: {
            requiresAuth: true,
            permission: "location_storefronts.update",
          },
          component: () =>
            import("./LocationSettingsEditCustomerPortalFormView.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          path: "edit/contact",
          name: routeNames.locationSettingsEditContact,
          meta: {
            requiresAuth: true,
            permission: "location_storefronts.update",
          },
          component: () => import("./LocationSettingsEditContactFormView.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          path: "edit/opening-self-storage",
          name: routeNames.locationSettingsEditFacilityOpeningHours,
          meta: {
            requiresAuth: true,
            permission: "location_storefronts.update",
          },
          component: () =>
            import("./LocationSettingsEditOpeningSelfStorageFormView.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          path: "edit/opening-office",
          name: routeNames.locationSettingsEditOfficeOpeningHours,
          meta: {
            requiresAuth: true,
            permission: "location_storefronts.update",
          },
          component: () =>
            import("./LocationSettingsEditOpeningOfficeFormView.vue"),
        },
        { fullscreen: false }
      ),
    ],
  },
];
