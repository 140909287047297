import dayjs from "dayjs";
import { isArray, isNumber, isString, uniq } from "lodash-es";
import type { LocationQuery } from "vue-router";

import type { IPaginationQueryParamsBase } from "@/api/types/general-types";
import { filterTableConfig } from "@/config/filter-table-config";
import type { IDataTableHeader } from "@/types/filter-table-types";
import type { Maybe, MaybeArray } from "@/types/utility-types";
import type { ArrayItemType } from "@/utils/type-utils";

import { toArray } from "./array-utils";
import { isValidISODateFormat } from "./date-utils";
import { getNumberOrThrow } from "./error-utils";

export interface DataOptions {
  page: number;
  itemsPerPage: number;
  sortBy: { key: string; order: "desc" | "asc" }[];
}

export interface DataPagination {
  page: number;
  itemsPerPage: number;
  pageStart: number;
  pageStop: number;
  pageCount: number;
  itemsLength: number;
}

export type IQueryParameters = LocationQuery;
export type IFilterParameters = LocationQuery;
export type SortQueryOptions = Pick<DataOptions, "sortBy">;
export type ISortBy = DataOptions["sortBy"];
export type ISortByItem = ArrayItemType<ISortBy>;
export type SortQueryNameMapping = Pick<
  IDataTableHeader,
  "value" | "sortColumnName"
>;

export function getDefaultPagination(): DataPagination {
  return {
    page: 1,
    itemsPerPage: filterTableConfig.itemsPerPage,
    itemsLength: 0,
    pageStart: 0,
    pageStop: 0,
    pageCount: 0,
  };
}

export function getDefaultFilterParameters(): IPaginationQueryParamsBase {
  return {
    page: getDefaultPagination().page,
    page_size: getDefaultPagination().itemsPerPage,
  };
}

export function parseQueryNumberArray(
  ids:
    | (string | number | null | undefined)[]
    | string[]
    | string
    | number
    | null
    | undefined
) {
  const cleanIds = toArray(ids)
    .filter((id) => id != null)
    .map((id) => parseQueryToNumber(id))
    .filter((id) => id != null) as number[];

  return uniq(cleanIds)
    .map((id) => String(id))
    .sort();
}

export function parseQueryToNumber(
  value?: string | null | (string | null)[] | number | number[]
): number | undefined {
  if (value == null) {
    return undefined;
  }

  let numberValue: string | number | null;
  if (isArray(value) && value.length > 0) {
    numberValue = value[0];
  } else {
    numberValue = value as string;
  }

  if (isNumber(numberValue)) {
    return numberValue;
  }

  try {
    return getNumberOrThrow(numberValue);
  } catch {
    return undefined;
  }
}

export function parseDateRangeQuery(
  dateRangeValues: MaybeArray<Maybe<string>> | undefined | null,
  fallback = undefined
) {
  if (!dateRangeValues) {
    return fallback;
  }

  const dateRange = toArray(dateRangeValues).filter(
    (d) => dayjs(d).isValid() && isValidISODateFormat(d)
  );

  if (dateRange.length !== 2) {
    return undefined;
  }

  return dateRange.sort() as string[];
}

export function getCleanedUpQuery(query: IQueryParameters) {
  return (Object.keys(query) as (keyof typeof query)[]).reduce((all, key) => {
    const value = query[key];
    return {
      ...all,
      [key]: (isArray(value) && value.length === 1
        ? value[0]
        : value) as string,
    };
  }, {} as IQueryParameters);
}

export function getFilter(
  name: string,
  value: (string | number) | (string | number)[] | undefined | null
): Record<string, string> {
  if (value === undefined) {
    return {};
  }

  const values = toArray(value).filter((value) => {
    if (value === undefined || (isString(value) && value.length <= 0)) {
      return false;
    } else {
      return true;
    }
  });

  if (values.length <= 0) {
    return {};
  }

  return {
    [`filter[${name}]`]: values.join(","),
  };
}
