import {
  allowedFileTypes,
  type IUseImageResizeOptions,
} from "@/composables/use-image-resize";
import type { IPermission } from "@/types/permission-types";

export const facilityMapTools = {
  uploadMap: {
    icon: "mdi-image-outline",
    name: "facilityMapEditorTool.floorPlan",
  },
  drawUnits: {
    icon: "mdi-pencil-outline",
    name: "facilityMapEditorTool.drawUnits",
  },
  linkUnits: {
    icon: "mdi-link",
    name: "facilityMapEditorTool.linkUnits",
  },
  zoomPlus: {
    icon: "mdi-plus-circle-outline",
    name: "facilityMapEditorTool.zoomIn",
  },
  zoomMinus: {
    icon: "mdi-minus-circle-outline",
    name: "facilityMapEditorTool.zoomOut",
  },
  zoomReset: {
    icon: "mdi-image-filter-center-focus-weak",
    name: "facilityMapEditorTool.zoomReset",
  },
  zoomToFit: {
    icon: "mdi-fit-to-page-outline",
    name: "facilityMapEditorTool.zoomToFit",
  },
  unitVisibilityOn: {
    icon: "mdi-eye-outline",
    name: "facilityMapEditorTool.unitVisibilityVisible",
  },
  unitVisibilityOff: {
    icon: "mdi-eye-off-outline",
    name: "facilityMapEditorTool.unitVisibilityHidden",
  },
  fullscreen: {
    icon: "mdi-fullscreen",
    name: "facilityMapEditorTool.fullscreen",
  },
  fullscreenExit: {
    icon: "mdi-fullscreen-exit",
    name: "facilityMapEditorTool.fullscreenExit",
  },
  move: {
    icon: "mdi-hand-back-right-outline",
    name: "facilityMapEditorTool.move",
  },
  scaleMap: {
    icon: "mdi-cursor-default-outline",
    name: "facilityMapEditorTool.scaleMap",
  },
  selectUnit: {
    icon: "mdi-cursor-default-outline",
    name: "facilityMapEditorTool.select",
  },
  drawUnitRectangle: {
    icon: "mdi-pencil-outline",
    name: "facilityMapEditorTool.drawUnitRectangle",
  },
  drawUnitPolygon: {
    icon: "mdi-vector-square-edit",
    name: "facilityMapEditorTool.drawUnitPolygon",
  },
  undo: {
    icon: "mdi-undo",
    name: "facilityMapEditorTool.undo",
  },
  redo: {
    icon: "mdi-redo",
    name: "facilityMapEditorTool.redo",
  },
} as const;

export const facilityMapPermissions: Record<string, IPermission> = {
  view: "facility_maps.view",
  create: "facility_maps.create",
  update: "facility_maps.update",
  delete: "facility_maps.delete",
};

export const facilityMapBackgroundMinSize = 100;
export const facilityMapTransparencyRange = {
  min: 0,
  max: 100,
  default: 50,
};

export const facilityMapUploadSettings: IUseImageResizeOptions = {
  allowedFileTypes,
  maxSize: 10000,
  maxDimensions: { width: 2000, height: 2000 },
};

export const facilityMapEditorClasses = {
  cursorPointer: "cursor-pointer",
  cursorGrab: "cursor-grab",
  cursorGrabbing: "cursor-grabbing",
};

export const facilityMapZoom = {
  speed: {
    mouseWheel: 0.005,
    buttons: 0.1,
  },
  levels: {
    min: 0.1,
    max: 10,
  },
};
