import { VueQueryPlugin } from "@tanstack/vue-query";
import type { App } from "vue";

export function vueQueryInit(app: App): void {
  // Adding global configuration to vue-query
  app.use(VueQueryPlugin, {
    enableDevtoolsV6Plugin: true,
    queryClientConfig: {
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          retry: 0,
          staleTime: 15 * 1000,
        },
      },
    },
  });
}
