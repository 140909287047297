<template>
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.836914"
      y="0.866272"
      width="3.51469"
      height="14.8557"
      fill="currentColor"
    />
    <path
      d="M12.6192 0.866272H9.44952C9.44952 5.14336 6.29063 7.72846 4.71118 8.48638L9.32139 15.722H13.5795L9.06529 9.09468C11.9787 6.69349 12.7046 2.92607 12.6192 0.866272Z"
      fill="currentColor"
    />
    <circle cx="15.7561" cy="13.6413" r="2.08088" fill="currentColor" />
  </svg>
</template>
