import { isObject, isString, set } from "lodash-es";

import httpClient from "@/api/http-client";
import type { IResponse } from "@/api/types/general-types";
import type { IRefreshTranslationsPayload } from "@/api/types/translation-types";
import en from "@/locales/en.json";
import { getApiBasePath } from "@/utils/request-utils";

const toEscape = "@".split("");

function getSanitizedValue(stringValue: string): string {
  return stringValue
    .split("")
    .map((char) => {
      if (toEscape.includes(char)) {
        return `{'${char}'}`;
      }

      return char;
    })
    .join("");
}

function replaceInJson(obj: unknown) {
  if (isObject(obj)) {
    Object.keys(obj).forEach((_key) => {
      const key = _key as keyof typeof obj;
      if (isObject(obj[key])) {
        replaceInJson(obj[key]);
      } else if (isString(obj[key])) {
        set(obj, key, getSanitizedValue(obj[key]));
      }
    });
  }
}

export function getTranslations(): Promise<IResponse<typeof en>> {
  return httpClient.get(`${getApiBasePath()}/translation`).then((res) => {
    replaceInJson(res.data);
    return res;
  });
}

export function refreshTranslations(
  payload?: IRefreshTranslationsPayload
): Promise<IResponse<typeof en>> {
  return httpClient.put(`${getApiBasePath()}/translation/refresh`, payload);
}
