import { createPinia } from "pinia";
import piniaPluginPersistedState from "pinia-plugin-persistedstate";
import type { App } from "vue";

export const pinia = createPinia();

export function piniaInit(app: App) {
  pinia.use(piniaPluginPersistedState);
  app.use(pinia);
}
