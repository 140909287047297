import { isInteger } from "lodash-es";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

export interface IUseTranslateResponse extends ReturnType<typeof useI18n> {
  ordinalConversion(num?: number): string;

  translateSimpleEntityList<T extends { name: string }>(items: T[]): T[];
}

export function useTranslate(): IUseTranslateResponse {
  const all = useI18n({ useScope: "global" });
  const { t } = all;

  return {
    ...all,
    // TODO: this is very strange: sometimes the locale-property is not a ref anymore. seems like it will be unwrapped automatically somehow somewhere.
    locale: ref(all.locale),
    ordinalConversion(num?: number) {
      if (num && isInteger(num) && num >= 0) {
        const pr = new Intl.PluralRules(all.locale.value, { type: "ordinal" });
        const rule = pr.select(num);

        const suffixes = new Map([
          ["one", t("generic.ordinal.one")],
          ["two", t("generic.ordinal.two")],
          ["few", t("generic.ordinal.few")],
          ["other", t("generic.ordinal.other")],
        ]);

        const suffix = suffixes.get(rule);
        return `${num}${suffix}`;
      }
      return "";
    },
    translateSimpleEntityList<T extends { name: string }>(items: T[]): T[] {
      return items.map((item) => ({
        ...item,
        name: t(item.name),
      })) as T[];
    },
  };
}
