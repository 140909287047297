import { routeNames } from "@/router/route-names";
import type { RouteMetaConfig } from "@/types/router";
import { buildModalRoute } from "@/utils/modal-utils";

export const routesEmail: RouteMetaConfig[] = [
  {
    path: "/emails",
    name: routeNames.emailOverview,
    component: () => import("./EmailListView.vue"),
    meta: {
      requiresAuth: true,
      permission: "emails.view",
    },
    children: [
      buildModalRoute(
        {
          path: "create",
          name: routeNames.emailCreate,
          meta: {
            requiresAuth: true,
            permission: "emails.create",
          },
          component: () => import("./EmailCreateForm.vue"),
        },
        { fullscreen: true }
      ),
      // I do this because i prevent the user from seeing the detail page briefly
      buildModalRoute(
        {
          path: ":id/duplicate",
          name: routeNames.emailDuplicateList,
          meta: {
            requiresAuth: true,
            permission: "emails.create",
          },
          component: () => import("./EmailCreateForm.vue"),
        },
        { fullscreen: true }
      ),
    ],
  },
  {
    path: "/emails/:id(\\d+)",
    name: routeNames.emailDetails,
    component: () => import("./EmailDetailView.vue"),
    meta: {
      requiresAuth: true,
      permission: "emails.view",
    },
    children: [
      buildModalRoute(
        {
          path: "edit",
          name: routeNames.emailEdit,
          meta: {
            requiresAuth: true,
            permission: "emails.update",
          },
          component: () => import("./EmailEditContentForm.vue"),
        },
        { fullscreen: true }
      ),
      buildModalRoute(
        {
          path: "duplicate",
          name: routeNames.emailDuplicate,
          meta: {
            requiresAuth: true,
            permission: "emails.create",
          },
          component: () => import("./EmailCreateForm.vue"),
        },
        { fullscreen: true }
      ),
    ],
  },
];
