<template>
  <div :id="id" class="teleport-target" />
</template>

<script lang="ts" setup>
import { isString } from "lodash-es";
import { computed } from "vue";

import type { ITeleportTarget } from "@/modules/teleports/teleports.config";

interface Props {
  name: string | ITeleportTarget;
}

const props = withDefaults(defineProps<Props>(), {});

const id = computed(() => {
  if (isString(props.name)) {
    return props.name;
  } else {
    return props.name.id;
  }
});
</script>

<style scoped lang="scss"></style>
