/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

import type { App } from "vue";
// Composables
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import { mdi } from "vuetify/iconsets/mdi";
import { de, es, fr, pl } from "vuetify/locale";

import { colors } from "@/config/color-config";
import { getPersistedLanguage } from "@/utils/user-settings-utils";

import { customIcons } from "./custom-icons";

export function getVuetifyInstance() {
  return createVuetify({
    components,
    locale: {
      locale: getPersistedLanguage(),
      fallback: "en",
      messages: { de, es, fr, pl },
    },
    theme: {
      themes: {
        light: {
          colors: {
            primary: colors.app.primary,
            black: colors.black,
            error: colors.app.danger,
            "desaturated-primary": colors.app["primary-light"],
            "kinn-white": colors.white,
            secondary: colors.blue["100"],
            "dark-grey": colors.grey["700"],
            info: colors.blue.DEFAULT,
          },
        },
      },
    },
    defaults: {
      VTextField: {
        color: "primary",
        variant: "underlined",
      },
      VProgressLinear: {
        color: "primary",
      },
      VSelect: {
        color: "primary",
        variant: "underlined",
      },
      VTextarea: {
        color: "primary",
        variant: "underlined",
        autoGrow: true,
        rows: 1,
      },
      VAutocomplete: {
        color: "primary",
        variant: "underlined",
        autoSelectFirst: true,
      },
      VCombobox: {
        color: "primary",
        variant: "underlined",
      },
      VDataTable: {
        color: "primary",
      },
      VCheckbox: {
        color: "primary",
      },
      VChip: {
        color: "primary",
      },
      VRadioGroup: {
        color: "primary",
      },
      VStepper: {
        color: "primary",
      },
      VSwitch: {
        color: "primary",
        inset: true,
      },
      VFileInput: {
        color: "primary",
        variant: "underlined",
      },
      VStepperItem: {
        color: "primary",
      },
    },
    icons: {
      defaultSet: "mdi",
      sets: {
        mdi,
        custom: customIcons,
      },
    },
  });
}

export const vuetify = getVuetifyInstance();

export function vuetifyInit(app: App) {
  app.use(vuetify);
}
