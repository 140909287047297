import { authRoutes } from "@/modules/auth/auth-routes";
import { bookingRoutes } from "@/modules/booking/booking-routes";
import { customerRoutes } from "@/modules/customer/customer-routes";
import { dashboardRoutes } from "@/modules/dashboard/dashboard-routes";
import { discountRoutes } from "@/modules/discount/discount-routes";
import { facilityMapRoutes } from "@/modules/facility-map/facility-map-routes";
import { routesInsurance } from "@/modules/insurances/routes-insurance";
import { pdfRendererRoutes } from "@/modules/pdf-renderer/pdf-renderer-routes";
import { routesProduct } from "@/modules/products/routes-products";
import { tasksRoutes } from "@/modules/tasks/task-routes";
import { unitTypeRoutes } from "@/modules/unit-types/unit-type-routes";
import { unitsRoutes } from "@/modules/units/units-routes";
import { routesUser } from "@/modules/users/routes-users";
import type { RouteMetaConfig } from "@/types/router";
import { routesConnectedApps } from "@/views/connected-apps/routes-connected-apps";
import { routesEmailDomain } from "@/views/email-domains/routes-email-domain";
import { routesEmailTemplate } from "@/views/email-templates/routes-email-template";
import { routesEmail } from "@/views/emails/routes-emails";
import { routesGlobalSettings } from "@/views/global-settings/routes-global-settings";
import { routesInvoices } from "@/views/invoices/routes-invoices";
import { routesLocationSettings } from "@/views/location-settings/routes-location-settings";
import { routesLocation } from "@/views/locations/routes-locations";
import { routesTenant } from "@/views/tenants/routes-tenants";

import { routeNames } from "./route-names";

const routes: RouteMetaConfig[] = [
  ...routesTenant,
  ...dashboardRoutes,
  ...routesLocation,
  ...unitTypeRoutes,
  ...bookingRoutes,
  ...customerRoutes,
  ...routesInsurance,
  ...routesInvoices,
  ...unitsRoutes,
  ...routesProduct,
  ...discountRoutes,
  ...routesEmail,
  ...routesEmailTemplate,
  ...routesEmailDomain,
  ...routesLocationSettings,
  ...routesGlobalSettings,
  ...routesConnectedApps,
  ...routesUser,
  ...facilityMapRoutes,
  ...pdfRendererRoutes,
  ...tasksRoutes,
  ...authRoutes,
  {
    path: "/maintenance",
    name: routeNames.maintenance,
    component: () => import("../views/maintenance/MaintenanceView.vue"),
    meta: {
      requiresAuth: true,
      permission: "tenants.view",
    },
  },
  {
    path: "/voting-portal",
    name: routeNames.votingPortal,
    component: () => import("../views/voting-portal/VotingPortalPage.vue"),
    meta: {
      requiresAuth: true,
      permission: "voting_portal.view",
    },
  },
  {
    path: "/forbidden",
    name: routeNames.forbidden,
    component: () => import("../views/errors/ErrorView.vue"),
    props: {
      type: "forbidden",
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: routeNames.notFound,
    component: () => import("../views/errors/ErrorView.vue"),
  },
];

export default routes;
