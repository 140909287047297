import { routeNames } from "@/router/route-names";
import type { RouteMetaConfig } from "@/types/router";
import { buildModalRoute } from "@/utils/modal-utils";

export const routesUser: RouteMetaConfig[] = [
  {
    path: "/users",
    name: routeNames.userOverview,
    component: () => import("./views/UserListView.vue"),
    meta: {
      requiresAuth: true,
      permission: "users.view",
    },
    children: [
      buildModalRoute(
        {
          name: routeNames.userCreate,
          path: "create",
          meta: {
            requiresAuth: true,
            permission: "users.create",
          },
          component: () => import("./views/UserCreateFormView.vue"),
        },
        { fullscreen: true }
      ),
    ],
  },
  {
    path: "/users/:id(\\d+)",
    name: routeNames.userDetails,
    component: () => import("./views/UserDetailView.vue"),
    meta: {
      requiresAuth: true,
      permission: "users.view",
    },
    children: [
      buildModalRoute(
        {
          path: "edit/general",
          name: routeNames.userEditGeneral,
          meta: {
            requiresAuth: true,
            permission: "users.update",
          },
          component: () => import("./views/UserEditGeneralForm.vue"),
        },
        { fullscreen: false }
      ),
    ],
  },
];
