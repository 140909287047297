import { uniqueId } from "lodash-es";
import { markRaw, type Ref, ref } from "vue";

import AppConfirmDialog from "@/components/dialogs/AppConfirmDialog.vue";
import AppLoadingDialog from "@/components/dialogs/AppLoadingDialog.vue";
import type { ITableRowConfirmData } from "@/types/action-menu-types";

type PropsType = Record<string, unknown>;

export interface DialogInstance<
  Component = unknown,
  Props extends PropsType = PropsType,
  Return = unknown,
> {
  id: string;
  resolve: (value: Return) => void;
  afterClose: Promise<Return>;
  component: Component;
  props: Props;
}

function createInstance<T, Props extends PropsType, Return>(
  component: T,
  props: Props
): DialogInstance<T, Props, Return> {
  let resolve: (type: Return) => void = () => undefined;

  const afterClose = new Promise<Return>((resolveFn) => {
    resolve = resolveFn;
  });

  return {
    id: uniqueId("dialog-"),
    resolve,
    afterClose,
    component,
    props,
  };
}

export interface ConfirmProps extends PropsType {
  okLabel?: ITableRowConfirmData["okLabel"];
  cancelLabel?: ITableRowConfirmData["cancelLabel"];
  title?: ITableRowConfirmData["title"];
  icon?: ITableRowConfirmData["icon"];
  message?: ITableRowConfirmData["message"];
  color?: ITableRowConfirmData["color"];
  loading?: boolean;
  resolve?: (value: boolean) => void;
  dataTestId?: string;
}

export interface LoadingProps extends PropsType {
  okLabel?: string;
  cancelLabel?: string;
  title?: string;
  message?: string;
  color?: string;
  loading?: boolean;
  resolve?: (value: boolean) => void;
  dataTestId?: string;
}

export type TAnyDialog =
  | DialogInstance<typeof AppConfirmDialog, ConfirmProps>
  | DialogInstance<typeof AppLoadingDialog, LoadingProps>;

const queue: Ref<TAnyDialog[]> = ref([]);

export function useDialogQueue(): {
  queue: typeof queue;
  push(item: DialogInstance): void;
} {
  return {
    queue,
    push(item: TAnyDialog) {
      queue.value.push(markRaw(item));
    },
  };
}

export interface UseDialogs {
  confirm: (
    opt: ConfirmProps
  ) => DialogInstance<typeof AppConfirmDialog, ConfirmProps>;
  loading: (
    opt: LoadingProps
  ) => DialogInstance<typeof AppLoadingDialog, LoadingProps>;
}

export function useDialogs(): UseDialogs {
  const { push } = useDialogQueue();

  return {
    confirm(opt: ConfirmProps) {
      const instance = createInstance(AppConfirmDialog, opt);

      instance.afterClose.finally(() => {
        queue.value = queue.value.filter((dialog) => dialog.id !== instance.id);
      });

      push(instance);
      return instance;
    },
    loading(opt: LoadingProps) {
      const instance = createInstance(AppLoadingDialog, opt);

      instance.afterClose.finally(() => {
        queue.value = queue.value.filter((dialog) => dialog.id !== instance.id);
      });

      push(instance);
      return instance;
    },
  };
}
