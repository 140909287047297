<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.4657 1.74513C12.1742 1.59169 11.8258 1.59169 11.5343 1.74513L3.03425 6.21881C2.7057 6.39174 2.5 6.73245 2.5 7.10373V7.5C2.5 8.05228 2.94772 8.5 3.5 8.5H20.5C21.0523 8.5 21.5 8.05228 21.5 7.5V7.10373C21.5 6.73245 21.2943 6.39174 20.9657 6.21881L12.4657 1.74513ZM17.5 10.5C16.9477 10.5 16.5 10.9477 16.5 11.5V16.5C16.5 17.0523 16.9477 17.5 17.5 17.5H18.5C19.0523 17.5 19.5 17.0523 19.5 16.5V11.5C19.5 10.9477 19.0523 10.5 18.5 10.5H17.5ZM2.5 21.5C2.5 22.0523 2.94772 22.5 3.5 22.5H20.5C21.0523 22.5 21.5 22.0523 21.5 21.5V20.5C21.5 19.9477 21.0523 19.5 20.5 19.5H3.5C2.94771 19.5 2.5 19.9477 2.5 20.5V21.5ZM11.5 10.5C10.9477 10.5 10.5 10.9477 10.5 11.5V16.5C10.5 17.0523 10.9477 17.5 11.5 17.5H12.5C13.0523 17.5 13.5 17.0523 13.5 16.5V11.5C13.5 10.9477 13.0523 10.5 12.5 10.5H11.5ZM5.5 10.5C4.94772 10.5 4.5 10.9477 4.5 11.5V16.5C4.5 17.0523 4.94772 17.5 5.5 17.5H6.5C7.05228 17.5 7.5 17.0523 7.5 16.5V11.5C7.5 10.9477 7.05228 10.5 6.5 10.5H5.5Z"
      fill="currentColor"
    />
  </svg>
</template>
