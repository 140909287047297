import { snackbarDurations } from "./snackbar-config";
import type {
  INotification,
  INotificationOptions,
  INotificationType,
} from "./snackbar-types";

/**
 * Returns the right configuration for vue-notification.
 * Adapt this if notification library changes
 * @param type
 * @param options
 */
export function toNotificationOptions(
  type: INotificationType,
  options: Partial<INotificationOptions> = {}
): INotification {
  const defaultOptions: INotificationOptions = {
    ...getDefaultNotificationOptions(type),
    ...options,
  };

  return {
    type: defaultOptions.type || "success",
    data: {
      showButton: defaultOptions.showButton,
    },
    duration: defaultOptions.duration,
    speed: 200,
  };
}

export function getDefaultNotificationOptions(
  type: INotificationType
): INotificationOptions {
  return {
    type,
    duration: snackbarDurations[type],
    showButton: true,
  };
}
