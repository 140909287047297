import { useMutation, useQuery } from "@tanstack/vue-query";

import {
  getTranslations,
  refreshTranslations,
} from "@/api/services/translation-service";
import { useTranslate } from "@/composables/use-translate";

export function useTranslationsQuery() {
  const { locale } = useTranslate();
  return useQuery({
    queryKey: ["translations", locale],
    queryFn: () => getTranslations(),
  });
}

export function useTranslationsRefreshMutation() {
  return useMutation({
    mutationFn: () => refreshTranslations(),
  });
}
