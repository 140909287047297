import type { PaymentMethod } from "@stripe/stripe-js";
import { isObject } from "lodash-es";
import type { ComputedRef, InjectionKey, Ref } from "vue";

import type {
  ICombinedPaymentMethod,
  IPaymentMethodItem,
  ISomePaymentMethod,
} from "@/api/types/payment-method-types";
import type { IBasePaymentMethod, PaymentData } from "@/config/payment-config";
import { paymentBrands, paymentMethodsRecord } from "@/config/payment-config";
import type { Maybe } from "@/types/utility-types";

export type IMaybePaymentMethod = PaymentMethod | string | null | undefined;

function isCardPaymentType(
  type: Maybe<IBasePaymentMethod | string>
): type is keyof typeof paymentBrands.card {
  return type != null && type in paymentBrands.card;
}

export function isOtherPaymentType(
  type: Maybe<IBasePaymentMethod | string>
): type is keyof typeof paymentBrands.other {
  return type != null && type in paymentBrands.other;
}

export function isPaymentMethodObject(
  input: IMaybePaymentMethod
): input is PaymentMethod {
  return !!input && isObject(input) && !!(input as PaymentMethod).object;
}

export function getPaymentMethodIdValue(
  paymentMethod: IMaybePaymentMethod
): string | undefined {
  return isPaymentMethodObject(paymentMethod)
    ? paymentMethod.id
    : paymentMethod || undefined;
}

export function getPaymentMethodIcon(
  method?: Pick<IPaymentMethodItem, "type" | "brand">
): PaymentData | undefined {
  const { type, brand } = method || {};

  if (isOtherPaymentType(type)) {
    return paymentBrands.other[type];
  } else if (isCardPaymentType(brand)) {
    return paymentBrands.card[brand];
  } else if (type != null) {
    // then it is a custom payment method, we just show the type (provided by the operator as custom value)
    return {
      ...paymentBrands.custom,
      title: type,
      icon: null,
    };
  }
  return undefined;
}

/**
 * @deprecated
 */
export interface ISubFormModel {
  loading: boolean;
  initialized: boolean;
  submitting: boolean;
  error: unknown | undefined;
}

/**
 * @deprecated
 */
export interface IUsePaymentPickerInjectionModel {
  locationId: ComputedRef<number | null>;
  customerId: ComputedRef<number | null>;
  isLoadingData: ComputedRef<boolean>;
  stripeAccountId: ComputedRef<string | undefined>;
  customerName: ComputedRef<string | undefined>;
  customerEmail: ComputedRef<string | undefined>;
  formSepa: Ref<ISubFormModel | undefined>;
  formCreditCard: Ref<ISubFormModel | undefined>;
  onSuccess: (paymentMethodId: IMaybePaymentMethod) => void;
  onError: (error: unknown) => void;
  onClose: () => void;
}

/**
 * @deprecated
 */
export const paymentPickerKey = Symbol(
  "uiPaymentPicker"
) as InjectionKey<IUsePaymentPickerInjectionModel>;

export function getPaymentMethodName(
  methodId: keyof typeof paymentMethodsRecord | string
): string {
  return (
    paymentMethodsRecord[methodId as keyof typeof paymentMethodsRecord] ||
    methodId
  );
}

/**
 * Currently the BE returns multiple different payment types.
 * To make our life easier we map all of them to one single type.
 */
export function toPaymentMethodItem(
  someVeryRandomPaymentMethodItem: ISomePaymentMethod
): IPaymentMethodItem {
  const paymentMethod: ICombinedPaymentMethod =
    someVeryRandomPaymentMethodItem as ICombinedPaymentMethod;

  return {
    type: paymentMethod.type,
    brand: paymentMethod.brand,
    last_four: paymentMethod.last_four,
    stripe_payment_method_id: paymentMethod.stripe_payment_method_id,
    sepa_mandate_link: paymentMethod.sepa_mandate_link,
    wallet_name: paymentMethod.wallet_name,
    external_url: paymentMethod.external_url,
  };
}
