import { useAppInformation } from "@/composables/use-app-verison";

function center(text: string, lineLength: number) {
  const diff = lineLength - text.length;
  const padding = Math.floor(diff / 2);
  return "".padStart(padding, " ") + text;
}

export function logHiringMessage() {
  const style = "color: #041239; line-height: 1.5;";
  const hiringText = "👉 We are hiring! 😎";
  const hiringUrl = "https://www.linkedin.com/company/72180545";
  const n = [
    [..."███    ██".split("")],
    [..."████   ██".split("")],
    [..."██ ██  ██".split("")],
    [..."██  ██ ██".split("")],
    [..."██   ████".split("")],
  ];
  const flippedN = n.map((row) => [...row].reverse());

  // Add new texts here to figure out the longest line. Needed for centering
  const lineLength = Math.max(
    hiringText.length,
    hiringUrl.length,
    n[0].length * 2 + 1
  );

  const lines: string[] = [" "];

  for (let line = 0; line < n.length; line++) {
    const lineText = n[line].join("") + " " + flippedN[line].join("");
    lines.push(center(lineText, lineLength));
  }
  lines.push("");
  lines.push(center(hiringText, lineLength));
  lines.push(center(hiringUrl, lineLength));
  lines.push(" ");

  logVersion(lineLength);
  // eslint-disable-next-line no-console
  console.log("%c" + lines.join("\n"), style);
}

export function logVersion(lineLength: number) {
  const { version, applicationName } = useAppInformation();
  const string = ` 💻 ${applicationName} v${version} `;
  const length = Math.max(lineLength, string.length);

  const line = "".padStart(length, "-");
  const message = `%c${line}\n${center(string, length)}\n${line}`;
  // eslint-disable-next-line no-console
  console.log(message, "font-weight: bold;");
}
