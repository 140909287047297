import type { RouteLocationNamedRaw } from "vue-router";

import { modalSizes } from "@/config/constants";
import { tasksConfig } from "@/modules/tasks/tasks-config";
import { routeNames } from "@/router/route-names";
import type { RouteMetaConfig } from "@/types/router";
import { buildModalRoute } from "@/utils/modal-utils";

export const tasksRoutes: RouteMetaConfig[] = [
  {
    path: "/tasks",
    name: routeNames.tasksOverview,
    component: () => import("./views/TasksListView.vue"),
    meta: {
      requiresAuth: true,
      permission: "tasks.view",
      listFilterName: "tasks",
    },
    children: [
      buildModalRoute(
        {
          name: routeNames.taskCreate,
          path: "create",
          meta: {
            requiresAuth: true,
            permission: "tasks.create",
          },
          component: () => import("./views/CreateEditTaskView.vue"),
        },
        { fullscreen: false, width: modalSizes.md }
      ),
      buildModalRoute(
        {
          name: routeNames.taskEdit,
          path: "edit/:id",
          meta: {
            requiresAuth: true,
            permission: "tasks.create",
          },
          component: () => import("./views/CreateEditTaskView.vue"),
        },
        { fullscreen: false, width: modalSizes.md }
      ),
      buildModalRoute(
        {
          name: routeNames.tasksNotificationSettings,
          path: "notification-settings",
          meta: {
            requiresAuth: true,
            permission: "tasks.create",
          },
          component: () => import("./views/TasksNotificationsView.vue"),
        },
        { fullscreen: false, width: modalSizes.md }
      ),
    ],
  },
  {
    path: "/tasks/show/:id",
    name: routeNames.taskMailRedirect,
    component: () => import("./views/TasksMailRedirect.vue"),
  },
];

export const taskRoutesFactory = {
  taskOverview(taskId?: number) {
    return {
      name: routeNames.tasksOverview,
      query: {
        task: taskId,
      },
    };
  },
  taskOverviewShowTask(taskId: number) {
    return {
      name: routeNames.tasksOverview,
      query: {
        "filter[search]": `ID:${taskId}`,
        page: "1",
        page_size: tasksConfig.itemsPerPageOptions[0],
        task: taskId,
      },
    };
  },
  taskEdit(taskId: number): RouteLocationNamedRaw {
    return {
      name: routeNames.taskEdit,
      params: {
        id: taskId,
      },
    };
  },
  taskCreate() {
    return { name: routeNames.taskCreate };
  },
  taskNotificationSettings() {
    return { name: routeNames.tasksNotificationSettings };
  },
};
