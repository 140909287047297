import type { RouteLocationNamedRaw } from "vue-router";

import { routeNames } from "@/router/route-names";
import type { RouteMetaConfig } from "@/types/router";
import { buildModalRoute } from "@/utils/modal-utils";

export const unitTypeRoutes: RouteMetaConfig[] = [
  {
    path: "/unit-types",
    name: routeNames.unitTypeOverview,
    component: () => import("./views/UnitTypeListView.vue"),
    meta: {
      requiresAuth: true,
      permission: "unit_types.view",
      listFilterName: "unit-types",
    },
    children: [
      buildModalRoute(
        {
          name: routeNames.unitTypeCreate,
          path: "create",
          meta: {
            requiresAuth: true,
            permission: "unit_types.create",
          },
          component: () => import("./views/UnitTypeCreateView.vue"),
        },
        { fullscreen: true }
      ),
    ],
  },
  {
    path: "/unit-types/:id(\\d+)",
    name: routeNames.unitTypeDetails,
    component: () => import("./views/UnitTypeDetailView.vue"),
    meta: {
      requiresAuth: true,
      permission: "unit_types.view",
    },
    children: [
      buildModalRoute(
        {
          path: "edit/general",
          name: routeNames.unitTypeEditGeneral,
          meta: {
            requiresAuth: true,
            permission: "unit_types.update",
          },
          component: () => import("./views/UnitTypeEditGeneralForm.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          path: "edit/images",
          name: routeNames.unitTypeEditImages,
          meta: {
            requiresAuth: true,
            permission: "unit_types.update",
          },
          component: () => import("./views/UnitTypeEditImagesView.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          path: "edit/storefront",
          name: routeNames.unitTypeEditStorefront,
          meta: {
            requiresAuth: true,
            permission: "unit_types.update",
          },
          component: () => import("./views/UnitTypeEditStorefrontForm.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          path: "edit/tax",
          name: routeNames.unitTypeEditTax,
          meta: {
            requiresAuth: true,
            permission: "unit_types.update",
          },
          component: () => import("./views/UnitTypeEditTaxForm.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          path: "booking-plan",
          name: routeNames.unitTypeAddBookingPlan,
          meta: {
            requiresAuth: true,
            permission: "unit_types.update",
          },
          component: () => import("./views/UnitTypeCreateBookingPlanForm.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          path: "booking-plan/:bookingPlanId(\\d+)",
          name: routeNames.unitTypeEditBookingPlan,
          meta: {
            requiresAuth: true,
            permission: "unit_types.update",
          },
          component: () => import("./views/UnitTypeEditBookingPlanForm.vue"),
        },
        { fullscreen: false }
      ),
    ],
  },
];

export const unitTypeRouteFactory = {
  overview(): RouteLocationNamedRaw {
    return {
      name: routeNames.unitTypeOverview,
    };
  },
  unitTypeDetails(unitTypeId: number): RouteLocationNamedRaw {
    return {
      name: routeNames.unitTypeDetails,
      params: {
        id: unitTypeId,
      },
    };
  },
};
