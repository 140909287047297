import { isEqual, isNumber, toNumber } from "lodash-es";
import { computed, type ComputedRef, watch } from "vue";
import { type RouteLocation, useRoute, useRouter } from "vue-router";

import type { Maybe } from "@/types/utility-types";
import { toArray } from "@/utils/array-utils";
import { toBoolean } from "@/utils/data-utils";
import { isValidISODateFormat } from "@/utils/date-utils";
import {
  getCleanedUpQuery,
  parseDateRangeQuery,
  parseQueryNumberArray,
  parseQueryToNumber,
} from "@/utils/query-helpers-utils";

export type IQueryType = RouteLocation["query"][""];

type ITypeMapping = {
  string: string;
  number: number;
  boolean: boolean;
};

export interface IQuerySerializer<T> {
  fromQuery(query: Maybe<IQueryType>): T | undefined;

  toQuery(value: T): IQueryType | undefined;
}

const noopSerializer: IQuerySerializer<string | string[]> = {
  fromQuery(query: Maybe<IQueryType>) {
    return query != null ? (query as string[]) : undefined;
  },
  toQuery(value: string) {
    return value;
  },
};

const singleStringSerializer: IQuerySerializer<string> = {
  fromQuery(query: Maybe<IQueryType>) {
    if (!query) {
      return undefined;
    }

    return toArray(query)[0] || undefined;
  },
  toQuery(value: string) {
    return value;
  },
};

const numberSerializer: IQuerySerializer<number> = {
  fromQuery(query: Maybe<IQueryType>): number | undefined {
    return parseQueryToNumber(query);
  },
  toQuery(value: number): IQueryType | undefined {
    if (!isNumber(value)) {
      return undefined;
    }

    return String(value);
  },
};

const numberArraySerializer: IQuerySerializer<number[]> = {
  fromQuery(query: Maybe<IQueryType>): number[] | undefined {
    const values = parseQueryNumberArray(query).map((value) =>
      parseFloat(value)
    );
    return values.length ? values : undefined;
  },
  toQuery(value: number[]): IQueryType | undefined {
    const values = toArray(value)
      .filter((v) => parseQueryToNumber(v))
      .filter((value) => !!value);
    return values.length ? values.sort().map(String) : undefined;
  },
};

const numberStringArraySerializer: IQuerySerializer<string[]> = {
  fromQuery(query: Maybe<IQueryType>): string[] | undefined {
    return numberArraySerializer.fromQuery(query)?.map(String);
  },
  toQuery(value: string[]): IQueryType | undefined {
    const values = toArray(value)
      .filter((v) => parseQueryToNumber(v))
      .filter((value) => !!value)
      .sort();
    return values.length ? values : undefined;
  },
};

const dateRangeSerializer: IQuerySerializer<string[]> = {
  fromQuery(query: Maybe<IQueryType>): string[] | undefined {
    return parseDateRangeQuery(query);
  },
  toQuery(value: string[]): IQueryType | undefined {
    const values = toArray(value)
      .filter((v) => isValidISODateFormat(v))
      .sort();
    return values.length === 2 ? values : undefined;
  },
};

export const querySerializers = {
  singleStringSerializer,
  noopSerializer,
  numberSerializer,
  numberArraySerializer,
  numberStringArraySerializer,
  dateRangeSerializer,
};

export function useQueryValue<TData>(
  name: string,
  serializer: IQuerySerializer<TData>
) {
  const route = useRoute();
  const router = useRouter();

  const raw = computed(() => {
    return route.query[name];
  });

  function setValue(newValue: TData | undefined) {
    const parsedNewValue = serializer.toQuery(newValue as TData);

    const oldQuery = { ...route.query };

    if (parsedNewValue == null) {
      delete oldQuery[name];
    } else {
      oldQuery[name] = parsedNewValue;
    }

    if (!isEqual(getCleanedUpQuery(oldQuery), getCleanedUpQuery(route.query))) {
      router.push({
        query: oldQuery,
      });
    }
  }

  const fromQueryValue = computed(() => serializer.fromQuery(raw.value));

  watch(
    [fromQueryValue, raw],
    () => {
      if (fromQueryValue.value == null && !!raw.value) {
        // the raw query value contains a value that cannot be successfully parsed to the desired formatted value.
        // remove it from the query then
        setValue(undefined);
      }
    },
    { immediate: true }
  );

  return {
    raw,
    formattedValue: computed<TData | undefined>({
      get() {
        return fromQueryValue.value;
      },
      set(newValue) {
        setValue(newValue);
      },
    }),
  };
}

export function useQueryParameter<TCastTo extends keyof ITypeMapping>(
  name: string,
  castTo: TCastTo = "string" as TCastTo
): ComputedRef<ITypeMapping[TCastTo] | undefined> {
  const route = useRoute();
  const result = computed(() => route?.query?.[name]);

  return computed(() => {
    const value = result.value;
    if (value === undefined || value === null) {
      return undefined;
    }

    switch (castTo) {
      case "number":
        return toNumber(value as string) as ITypeMapping[TCastTo];
      case "boolean":
        return toBoolean(value as string) as ITypeMapping[TCastTo];
      case "string":
      default:
        return value as ITypeMapping[TCastTo];
    }
  });
}
