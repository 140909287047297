<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    focusable="false"
    aria-hidden="true"
    viewBox="0 0 14 14"
    width="24"
    height="24"
    fill="currentColor"
  >
    <path
      d="m 5.9066957,11.238047 c -1.91331,-0.2581 -3.60408,-1.218 -4.4277,-2.5137002 -0.54923,-0.864 -0.63584,-2.1051 -0.21035,-3.0143 0.45403,-0.9703 1.22102,-1.7009 2.37474,-2.2621 0.87662,-0.4265 1.78887,-0.6509 2.83076,-0.6965 l 0.84193,-0.037 -0.0185,1.5241 -0.0185,1.5241 -0.58326,0.032 c -1.24391,0.067 -2.58298,0.3862 -3.43627,0.8186 -0.77098,0.3906 -1.29072,1.0854 -1.19674,1.5997 0.20198,1.1053 1.84154,2.1406002 3.88416,2.4526002 1.3271,0.2027 2.42865,-0.013 3.32739,-0.6511 0.53435,-0.3795002 0.7816393,-0.6671002 1.0703393,-1.2449002 0.5293,-1.0592 0.52525,-2.7478 -0.009,-3.8902 -0.4199193,-0.8973 -1.4226593,-1.676 -2.4305393,-1.8874 -0.3877,-0.081 -0.54053,-0.2584 -0.22302,-0.2584 0.74906,0 2.3405993,0.488 3.1552693,0.9675 1.02953,0.606 1.89902,1.7075 2.09611,2.6555 0.43836,2.1085 -1.32322,4.1094002 -4.1821393,4.7502002 -0.74764,0.1676 -2.11058,0.2307 -2.84436,0.1316 z m 0.78401,-0.913 c -1.36973,-0.4835002 -2.05346,-1.1888002 -2.05346,-2.1179002 0,-0.6806 0.30589,-1.1571 1.05369,-1.6412 0.36303,-0.235 1.37537,-0.6762 1.55163,-0.6762 0.0473,0 0.0708,0.7641 0.0708,2.2987 0,1.794 -0.0188,2.2984002 -0.0858,2.2973002 -0.0472,-8e-4 -0.28879,-0.073 -0.53692,-0.1607 z"
    />
  </svg>
</template>
<script setup lang="ts"></script>
