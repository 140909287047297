import type { InjectionKey } from "vue";
import { type RouteRecordRaw, useRouter } from "vue-router";

import { modalSizes } from "@/config/constants";
import type { Anchor } from "@/types/vuetify-types";

export interface IRoutableModalState {
  hasChanges: boolean;
  open: boolean;
  redirectTo?: string;
}

export const routableModalKey = Symbol() as InjectionKey<IRoutableModalState>;

export type IRoutableModalRouteOptions = {
  fullscreen?: boolean;
  persistent?: boolean;
  scrollable?: boolean;
  maxWidth?: number | string;
  minWidth?: number | string;
  width?: number | string;
  origin?: Anchor | "auto" | "overlap";
  transition?: string;
};

export const routableModalDefaultOptions: IRoutableModalRouteOptions = {
  fullscreen: true,
  minWidth: modalSizes.lg,
  origin: "center center",
  persistent: true,
  scrollable: false,
  transition: "dialog-bottom-transition",
  width: "auto",
};

export function getRoutableModalOptions(
  opt?: Partial<IRoutableModalRouteOptions>
): IRoutableModalRouteOptions {
  return {
    ...routableModalDefaultOptions,
    ...(opt || {}),
    maxWidth: !opt?.fullscreen ? modalSizes.lg : undefined,
  };
}

export function getCurrentModalRouteConfig():
  | IRoutableModalRouteOptions
  | undefined {
  const router = useRouter();
  return getRoutableModalOptions(router.currentRoute.value?.meta.modal);
}

export function buildModalRoute(
  config: RouteRecordRaw,
  modalConfig?: Partial<IRoutableModalRouteOptions>
): RouteRecordRaw {
  return {
    path: config.path,
    component: () =>
      import("../views/containers/AppRoutableModalContainer.vue"),
    children: [
      {
        ...config,
        path: "",
        meta: {
          ...(config.meta || {}),
          modal: modalConfig,
        },
      },
    ],
  };
}
