import { isObject, uniqueId } from "lodash-es";
import type { Component } from "vue";
import { useRoute, useRouter } from "vue-router";

import type { IPermission } from "@/types/permission-types";

import { type IManagerFeature, useManagerFeature } from "./use-manager-feature";
import { useUser } from "./use-user";

export interface IMenuItem {
  id: string;
  title: string;
  path: string;
  icon: string;
  permission: IPermission | IPermission[];
  feature?: IManagerFeature;

  props: {
    to: string;
    color: string;
    link: boolean;
    dataTestId: string;
    prependIcon: string;
  };
}

export interface IMenuSection {
  title: string;
  permission?: IPermission | IPermission[];
  items: (IMenuItem | IMenuGroup)[];
}

export interface IMenuGroup {
  id: string;
  title: string;
  icon: string;
  permission?: IPermission | IPermission[];
  items: IMenuItem[];
}

export function isMenuGroup(item: unknown): item is IMenuGroup {
  return (item as IMenuGroup).items != null;
}

export function isComponentIcon(icon: string | Component): icon is Component {
  return isObject(icon);
}

export function useNavigationBuilder() {
  const router = useRouter();
  const route = useRoute();
  const { canSome } = useUser();
  const { isFeatureDisabled } = useManagerFeature();

  function getItem(routeName: string, icon: string | Component) {
    const routeData = router.resolve({ name: routeName });
    const path = routeData.path;
    const name = routeData.name;
    const meta = routeData.meta;

    const result = {
      id: uniqueId("menu-item-"),
      title: `default.menu.${(name as string) || ""}`,
      path: path,
      icon: icon,
      permission: meta?.permission,
      feature: meta?.feature,
    };

    return {
      ...result,

      props: {
        to: result.path,
        color: "primary",
        link: true,
        dataTestId: "main-menu-item",
        prependIcon: icon,
        active: route.path.startsWith(path),
      },
    } as IMenuItem;
  }

  function getMenu(config: IMenuSection[]): (IMenuSection & { id: string })[] {
    const validSections: IMenuSection[] = [];

    for (const sectionOriginal of config) {
      const section: IMenuSection = {
        ...sectionOriginal,
        items: [],
      };
      const children = sectionOriginal.items;

      if (children.length <= 0 || !canSome(sectionOriginal.permission)) {
        continue;
      }

      for (const childGroup of children) {
        if (!canSome(childGroup.permission)) {
          // skipping if none of the given permissions is allowed
          continue;
        }

        if (isMenuGroup(childGroup)) {
          // Filtering out not allowed children
          const groupCopy: IMenuGroup = {
            ...childGroup,
            items: childGroup.items.filter(
              (item) =>
                canSome(item.permission) && !isFeatureDisabled(item.feature)
            ),
          };

          // Only add group if it contains children, else skip it
          if (groupCopy.items.length) {
            section.items.push(groupCopy);
          }
        } else {
          // If it is just a normal menu item, add it:
          if (!isFeatureDisabled(childGroup.feature)) {
            section.items.push(childGroup);
          }
        }
      }

      // If the section contains valid items, add it
      if (section.items.length) {
        validSections.push(section);
      }
    }

    return validSections.map((s) => ({ ...s, id: uniqueId("menu-section-") }));
  }

  function getGroup(options: {
    title: string;
    icon: string;
    items: IMenuItem[];
  }): IMenuGroup {
    return {
      id: uniqueId("menu-group-"),
      title: options.title,
      icon: options.icon,
      items: options.items,
    };
  }

  return {
    getItem,
    getMenu,
    getGroup,
  };
}
