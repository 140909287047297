import { ref, watch } from "vue";

import { useUser } from "./use-user";

export function useIntercom() {
  const isIntercomInitialized = ref(false);

  const user = useUser();

  function bootIntercom() {
    if (
      import.meta.env.VITE_APP_ENV === "development" ||
      isIntercomInitialized.value ||
      !user.user.value?.environment_id
    )
      return;

    window.Intercom("boot", {
      app_id: import.meta.env.VITE_APP_INTERCOM_APP_ID,
      user_id: user.user.value?.environment_id,
      user_hash: user.user.value?.intercom_hash ?? undefined, // HMAC using SHA-256
      language_override: user.user.value?.language_id ?? undefined,
      email: user.user.value ? user.email.value : undefined,
      name: user.user.value ? user.fullName.value : undefined,
      // TODO: BE has to fix this type and provide use with the company name because this is required by Intercom
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      company: (user.user.value?.intercom_company as any) ?? undefined,
    });
    isIntercomInitialized.value = true;
  }

  function shutdownIntercom() {
    if (import.meta.env.VITE_APP_ENV === "development") return;
    window.Intercom("shutdown");
    isIntercomInitialized.value = false;
  }

  function resetIntercom() {
    if (isIntercomInitialized.value) {
      shutdownIntercom();
    }

    bootIntercom();
  }

  watch(
    () => user.user.value,
    (newVal, oldVal) => {
      if (newVal && newVal !== oldVal) {
        resetIntercom();
      }
    }
  );

  return { bootIntercom, shutdownIntercom, resetIntercom };
}
