import { useQueryClient } from "@tanstack/vue-query";
import { onMounted, watch } from "vue";

import { useAuthStore } from "@/store/auth-store";

export function useVueQueryCleanup() {
  const queryClient = useQueryClient();
  const authStore = useAuthStore();

  onMounted(() => {
    watch(
      () => authStore.isAuthenticated,
      async (isAuthenticated) => {
        if (isAuthenticated) {
          // When authenticated during runtime refetch all queries to apply new auth token
          await queryClient.resetQueries({
            predicate() {
              return true;
            },
            type: "all",
          });
        } else {
          setTimeout(() => {
            queryClient.removeQueries({
              predicate() {
                return true;
              },
              exact: false,
              type: "inactive",
            });
          });
        }
      }
    );
  });
}
