import dayjs from "dayjs";
import { isNumber } from "lodash-es";
import { computed } from "vue";

import type { ISimpleEntity } from "@/api/types/general-types";
import { useTranslate } from "@/composables/use-translate";
import { useUser } from "@/composables/use-user";
import { getPaymentMethodName } from "@/utils/payment-method-utils";

export const DATE_FORMAT_ISO_DATE = "YYYY-MM-DD";

export function formatNumbersWithFloatingDot(
  origin: number,
  decimalPoints = 2
): number {
  return Number(Number.parseFloat(origin.toString()).toFixed(decimalPoints));
}

export function formatIdString(id: number | null | undefined): string {
  const arr = [];
  if (id == null) {
    return "";
  }
  if (!Number(id)) {
    return id.toString();
  }
  const strId = id.toString();

  for (let i = 0; i < strId.length; i += 4) {
    arr.push(strId.substring(i, i + 4));
  }

  return arr.join("-");
}

export function formatDefaultPaymentMethods(methods?: ISimpleEntity[] | null) {
  return methods?.map((method) => ({
    id: method.id,
    methodId: method.name,
    name: getPaymentMethodName(method.name),
  }));
}

/**
 * Returns the current currency symbol based on the users locale
 * @param locale de | en | sv
 * @param currency the currency symbol
 */
export function getCurrencySymbol(locale: string, currency: string) {
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    currencyDisplay: "narrowSymbol",
  });

  return formatter.format(0).replace(/\d/g, "").trim();
}

export function useCurrency() {
  const { locale } = useTranslate();
  const { currency: userCurrency } = useUser();
  const currency = computed(() => userCurrency.value || "EUR");

  return {
    symbol: computed(() => getCurrencySymbol(locale.value, currency.value)),
    currency,
  };
}

/**
 * @deprecated use `useFormat()` -> `parseStringToNumber`
 * Parses a number string in the english format "nnn.dd" (e.g. 100.12) to numbers.
 * Be aware that it does not handle german format like "100,12".
 */
export function parseToNumber(
  numberString: string | number,
  fallback = 0
): number {
  if (isNumber(numberString)) {
    return numberString;
  }

  if (numberString == null) {
    return fallback;
  }

  const parsed = parseFloat(numberString.replaceAll(",", "").trim());

  if (isNaN(parsed)) {
    return fallback;
  }

  return parsed;
}

export function formatISODate(date: string | Date | null | undefined): string {
  return date && dayjs(date).isValid()
    ? dayjs(date).format(DATE_FORMAT_ISO_DATE)
    : "";
}

export function formatPhoneNumber(data: {
  phone_prefix: string | null | undefined;
  phone_number: string | null | undefined;
}): string {
  return [data.phone_prefix, data.phone_number]
    .filter((d) => !!(d || "").trim())
    .join(" ");
}

export function formatDate(
  dateString: string | Date | null | undefined,
  locale: string
): string | undefined {
  if (dateString == null) {
    return;
  }

  const loc = locale === "en" ? "en-gb" : locale;

  return dayjs(dateString).isValid()
    ? dayjs(dateString).locale(loc).format("ll")
    : undefined;
}
