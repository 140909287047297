import type { Config } from "tailwindcss";

const colors = {
  transparent: "transparent",
  current: "currentColor",
  white: "#ffffff",
  black: "#000",
  blue: {
    DEFAULT: "#0A45E7",
    980: "#102256",
    950: "#11225A",
    900: "#133695",
    800: "#0F39BE",
    700: "#0A45E7",
    600: "#115dff",
    500: "#287eff",
    400: "#50a4ff",
    300: "#88c5ff",
    200: "#b9daff",
    100: "#d7e9ff",
    50: "#edf6ff",
  },
  green: {
    DEFAULT: "#2bbc58",
    950: "#072c14",
    900: "#17502b",
    800: "#1a6131",
    700: "#1b7a39",
    600: "#1e9b45",
    500: "#2bbc58",
    400: "#5bd882",
    300: "#8ce9a9",
    200: "#bef4ce",
    100: "#defae7",
    50: "#f1fcf3",
  },
  orange: {
    DEFAULT: "#fc8413",
    950: "#441906",
    900: "#7e3510",
    800: "#9c3e10",
    700: "#c54f09",
    600: "#C85D00",
    500: "#fc8413",
    400: "#fea139",
    300: "#ffc168",
    200: "#ffdea9",
    100: "#fff0d4",
    50: "#fff8ed",
  },
  red: {
    DEFAULT: "#ed2215",
    950: "#4b0804",
    900: "#881b14",
    800: "#a5180f",
    700: "#c8180d",
    600: "#ed2215",
    500: "#FF5449",
    400: "#ff6d64",
    300: "#ffa39d",
    200: "#ffc9c5",
    100: "#ffe1df",
    50: "#fff2f1",
  },

  teal: {
    DEFAULT: "#00ccde",
    950: "#053847",
    900: "#125667",
    800: "#10677a",
    700: "#088096",
    600: "#0096AC",
    500: "#00ccde",
    400: "#18e9f8",
    300: "#60f8ff",
    200: "#a1fcff",
    100: "#cdffff",
    50: "#ebfffe",
  },

  grey: {
    DEFAULT: "#717171",
    950: "#000000",
    900: "#333333",
    800: "#585858",
    700: "#717171",
    600: "#818181",
    500: "#969696",
    400: "#BDBDBD",
    300: "#d1d1d1",
    200: "#E0E0E0",
    100: "#f6f6f6",
    50: "#FFFFFF",
  },
};

export default {
  important: true,
  content: ["./index.html", "./src/**/*.{js,ts,jsx,tsx,vue}"],
  theme: {
    extend: {
      fontSize: {
        xxs: 10 / 16 + "rem",
      },
      cursor: {
        pencil:
          'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAxOSAxOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0yLjA3MDMxIDE2LjMyODFIMy40NzAzMUwxMi4wOTUzIDcuNzAzMTJMMTAuNjk1MyA2LjMwMzEyTDIuMDcwMzEgMTQuOTI4MUwyLjA3MDMxIDE2LjMyODFaTTE2LjM3MDMgNi4yNTMxM0wxMi4xMjAzIDIuMDUzMTNMMTMuNTIwMyAwLjY1MzEyNUMxMy45MDM2IDAuMjY5NzkyIDE0LjM3NDUgMC4wNzgxMjUgMTQuOTMyOCAwLjA3ODEyNUMxNS40OTExIDAuMDc4MTI1IDE1Ljk2MiAwLjI2OTc5MiAxNi4zNDUzIDAuNjUzMTI1TDE3Ljc0NTMgMi4wNTMxM0MxOC4xMjg2IDIuNDM2NDYgMTguMzI4NiAyLjg5ODk2IDE4LjM0NTMgMy40NDA2MkMxOC4zNjIgMy45ODIyOSAxOC4xNzg2IDQuNDQ0NzkgMTcuNzk1MyA0LjgyODEyTDE2LjM3MDMgNi4yNTMxM1pNMTQuOTIwMyA3LjcyODEzTDQuMzIwMzEgMTguMzI4MUgwLjA3MDMxMjVMMC4wNzAzMTI1IDE0LjA3ODFMMTAuNjcwMyAzLjQ3ODEzTDE0LjkyMDMgNy43MjgxM1oiIGZpbGw9ImJsYWNrIi8+Cjwvc3ZnPgo=") 0 19, auto',
      },
      letterSpacing: {
        wide: "0.5px",
        wider: "1px",
        widest: "1.8px",
      },
      lineHeight: {
        tight: "120%",
      },
    },
    fontFamily: {
      sans: ["Metropolis", "Roboto", "Helvetica", "Arial", "sans-serif"],
    },
    boxShadow: {
      DEFAULT:
        "0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14),0 1px 3px 0 rgba(0, 0, 0, .12)",
      lg: "0px 2px 6px 0px #0000001A",
      xl: "0px 4px 12px 0px #0000004D",
      none: "0 0 #000000",
    },
    colors: {
      primary: colors.blue,
      ...colors,

      // semantic colors
      app: {
        white: "#fafbff",
        primary: colors.blue[700],
        "primary-light": colors.blue[50],
        success: colors.green.DEFAULT,
        separator: colors.grey[200],
        border: colors.grey[300],
        disabled: colors.grey[200],
        muted: colors.grey[500],
        subtitle: colors.grey[700],
        danger: colors.red[700],
        black: colors.blue[980],
      },
    },
  },
  plugins: [],
} satisfies Config;
