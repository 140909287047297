import { defineStore } from "pinia";

import type { IPermission } from "@/types/permission-types";

export interface IPermissionStoreModel {
  permissions: IPermission[] | null;
}

export const usePermissionStore = defineStore("permissions", {
  state: () =>
    ({
      permissions: null,
    }) as IPermissionStoreModel,
  actions: {
    async reset() {
      this.permissions = null;
    },
  },
  persist: true,
});
