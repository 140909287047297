import { routeNames } from "@/router/route-names";
import type { RouteMetaConfig } from "@/types/router";
import { buildModalRoute } from "@/utils/modal-utils";

export const routesEmailDomain: RouteMetaConfig[] = [
  {
    path: "/email-domains",
    name: routeNames.emailDomainOverview,
    component: () => import("./EmailDomainsList.vue"),
    meta: {
      requiresAuth: true,
      permission: "email_domains.view",
    },
    children: [
      buildModalRoute(
        {
          path: "create",
          name: routeNames.emailDomainCreate,
          meta: {
            requiresAuth: true,
            permission: "email_domains.create",
          },
          component: () => import("./EmailDomainCreateForm.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          path: ":id(\\d+)/verify",
          name: routeNames.emailDomainVerify,
          meta: {
            requiresAuth: true,
            permission: "email_domains.update",
          },
          component: () => import("./EmailDomainVerifyForm.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          path: ":id(\\d+)/edit",
          name: routeNames.emailDomainEdit,
          meta: {
            requiresAuth: true,
            permission: "email_domains.update",
          },
          component: () => import("./EmailDomainEditForm.vue"),
        },
        { fullscreen: false }
      ),
    ],
  },
];
