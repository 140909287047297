import type { RouteLocationNamedRaw } from "vue-router";

import { routeNames } from "@/router/route-names";
import type { RouteMetaConfig } from "@/types/router";
import { buildModalRoute } from "@/utils/modal-utils";

export const routesProduct: RouteMetaConfig[] = [
  {
    path: "/products",
    name: routeNames.productOverview,
    component: () => import("./views/ProductListView.vue"),
    meta: {
      requiresAuth: true,
      permission: "products.view",
      listFilterName: "products",
    },
    children: [
      buildModalRoute(
        {
          name: routeNames.productCreate,
          path: "create",
          meta: {
            requiresAuth: true,
            permission: "products.create",
          },
          component: () => import("./views/ProductCreateFormView.vue"),
        },
        { fullscreen: true }
      ),
    ],
  },
  {
    path: "/products/:id(\\d+)",
    name: routeNames.productDetails,
    component: () => import("./views/ProductDetailView.vue"),
    meta: {
      requiresAuth: true,
      permission: "products.view",
    },
    children: [
      buildModalRoute(
        {
          path: "edit/general",
          name: routeNames.productEditGeneral,
          meta: {
            requiresAuth: true,
            permission: "products.update",
          },
          component: () => import("./views/ProductEditGeneralForm.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          path: "edit/tax",
          name: routeNames.productEditTax,
          meta: {
            requiresAuth: true,
            permission: "products.update",
          },
          component: () => import("./views/ProductEditTaxForm.vue"),
        },
        { fullscreen: false }
      ),
    ],
  },
];

export const productRoutesFactory = {
  productDetail(id: number | string): RouteLocationNamedRaw {
    return {
      name: routeNames.productDetails,
      params: {
        id,
      },
    };
  },
};
