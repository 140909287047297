import type { AxiosInstance } from "axios";

import httpClient from "@/api/http-client";
import type { IAuth, IUser } from "@/api/types/auth-types";
import type { IResponse } from "@/api/types/general-types";
import { apiVersion } from "@/config/constants";
import { fallbackLocale } from "@/config/locale-config";
import { i18n, type ILanguages } from "@/plugins/i18n-plugin";
import { useAuthStore } from "@/store/auth-store";
import { usePermissionStore } from "@/store/permission-store";
import type { IPermission } from "@/types/permission-types";
import { getAuthSettings } from "@/utils/auth-utils";

export class AuthService {
  // requests will be performed on this entity
  protected entity: string;
  // api version
  protected version: string;
  // base path to the entity
  protected basePath: string;

  protected httpClient: AxiosInstance;

  constructor(entity: string, version?: string) {
    this.entity = entity;
    this.version = version || apiVersion;
    this.basePath = `/${this.version}/${this.entity}`;
    this.httpClient = httpClient;
  }

  getToken(code: string): Promise<IAuth> {
    const { tokenUrl, scope, clientId, clientSecret, callbackUrl } =
      getAuthSettings();

    return this.httpClient.post(
      tokenUrl,
      {
        code,
        scope,
        grant_type: "authorization_code",
        client_id: clientId,
        client_secret: clientSecret,
        redirect_uri: callbackUrl,
      },
      { withCredentials: true }
    );
  }
  refreshToken(refreshToken: string): Promise<IAuth> {
    const { tokenUrl, scope, clientId, clientSecret } = getAuthSettings();

    return this.httpClient.post(
      tokenUrl,
      {
        scope,
        refresh_token: refreshToken,
        grant_type: "refresh_token",
        client_id: clientId,
        client_secret: clientSecret,
      },
      { withCredentials: true }
    );
  }

  fetchPermissions(): Promise<IResponse<{ name: IPermission }[]>> {
    return this.httpClient.get(
      `${import.meta.env.VITE_APP_API_URL}/${this.version}/permission/user`
    );
  }

  fetchUser(): Promise<IResponse<IUser>> {
    return this.httpClient.get(`${this.basePath}/user`);
  }

  updateClientData() {
    // enqueue any operation that should happen directly after the login
    return Promise.all([
      this.fetchUser().then((user) => {
        const locale: ILanguages =
          (user.data.language_id as ILanguages) || fallbackLocale;
        i18n.global.locale = locale.toLowerCase() as ILanguages;
        const authStore = useAuthStore();
        authStore.setUserData(user.data);
      }),
      this.fetchPermissions().then((permissions) => {
        const permissionStore = usePermissionStore();
        permissionStore.permissions = (permissions.data || []).map(
          (p) => p.name
        );
      }),
    ]);
  }

  getLoginRedirectUrl(code: string) {
    const { authorizeUrl, callbackUrl, scope, clientId } = getAuthSettings();

    const params = {
      state: code,
      scope,
      response_type: "code",
      host: "manager",
      client_id: clientId,
      ...this.getDefaultParams(callbackUrl),
    };

    return this.urlWithQueryParams(authorizeUrl, params);
  }

  getChangePasswordUrl(email: string) {
    const { changePasswordUrl } = getAuthSettings();

    const params = {
      email,
      redirect_uri: `${window.location.origin}/auth-attempt`,
    };

    return this.urlWithQueryParams(changePasswordUrl, params);
  }

  getLogoutRedirectUrl(currentAccessToken: string) {
    const { logoutUrl } = getAuthSettings();

    const params = {
      host: "manager",
      redirect_uri: `${window.location.origin}/login`,
      token: currentAccessToken,
    };

    return this.urlWithQueryParams(logoutUrl, params);
  }

  getDefaultParams(redirectUrl?: string) {
    const language = "en";
    const params: Record<string, string> = {
      language,
    };

    if (redirectUrl) {
      params.redirect_uri = redirectUrl;
    }

    return params;
  }

  urlWithQueryParams(
    givenUrl: string,
    params: ReturnType<typeof this.getDefaultParams>
  ) {
    const url = new URL(givenUrl);
    url.search = new URLSearchParams(params).toString();
    return url.toString();
  }
}

export const authService = new AuthService("auth");
