import type { App } from "vue";
import { createI18n } from "vue-i18n";

import { fallbackLocale } from "@/config/locale-config";
import en from "@/locales/en.json";
import { getPersistedLanguage } from "@/utils/user-settings-utils";

const locale = getPersistedLanguage();

export type ILanguages = "en" | "de" | "fr" | "pl";
type IMessageSchema = typeof en;

export const i18n = createI18n<[IMessageSchema], ILanguages>({
  legacy: false,
  silentTranslationWarn: true,
  locale,
  fallbackLocale,
  missingWarn: false,
});

export function i18nInit(app: App) {
  app.use(i18n);
}
