export interface ITeleportTarget {
  to: string;
  id: string;
}

function getTeleport(name: string): ITeleportTarget {
  return {
    to: `#teleport-${name}`,
    id: `teleport-${name}`,
  };
}
export const teleportTargets = {
  body: getTeleport("body"),
};
