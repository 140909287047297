import { routeNames } from "@/router/route-names";
import type { RouteMetaConfig } from "@/types/router";
import { buildModalRoute } from "@/utils/modal-utils";

export const routesEmailTemplate: RouteMetaConfig[] = [
  {
    path: "/email-templates",
    name: routeNames.emailTemplateOverview,
    component: () => import("./EmailTemplateList.vue"),
    meta: {
      requiresAuth: true,
      permission: "email_templates.view",
    },
    children: [
      buildModalRoute(
        {
          name: routeNames.emailTemplateCreate,
          path: "create",
          meta: {
            requiresAuth: true,
            permission: "email_templates.create",
          },
          component: () => import("./EmailTemplatesForm.vue"),
        },
        { fullscreen: true }
      ),
      buildModalRoute(
        {
          name: routeNames.emailTemplateEdit,
          path: ":templateId(\\d+)/edit",
          meta: {
            requiresAuth: true,
            permission: "email_templates.update",
          },
          props: true,
          component: () => import("./EmailTemplatesForm.vue"),
        },
        { fullscreen: true }
      ),
    ],
  },
];
