<template>
  <AppSPASkeleton />
</template>

<script lang="ts" setup>
import { onMounted } from "vue";

import AppSPASkeleton from "@/components/skeletons/AppSPASkeleton.vue";
import router from "@/router";
import { routeNames } from "@/router/route-names";
import { useAuthStore } from "@/store/auth-store";

const authStore = useAuthStore();

onMounted(() => {
  authStore
    .initializeSession()
    .then(() => {
      router.replace({ name: routeNames.login });
    })
    .catch(() => {
      authStore.logout();
    });
});
</script>

<style scoped lang="scss"></style>
