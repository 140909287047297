import { routeNames } from "@/router/route-names";
import type { RouteMetaConfig } from "@/types/router";
import { buildModalRoute } from "@/utils/modal-utils";

export const routesGlobalSettings: RouteMetaConfig[] = [
  {
    path: "/global-settings",
    name: routeNames.globalSettings,
    component: () => import("./GlobalSettingsDetailView.vue"),
    meta: {
      requiresAuth: true,
      permission: "tenant_storefronts.view",
    },
    children: [
      buildModalRoute(
        {
          name: routeNames.globalSettingsEditColors,
          path: "edit/color-palette",
          meta: {
            requiresAuth: true,
            permission: "tenant_storefronts.update",
          },
          component: () => import("./GlobalSettingsColorPaletteEditView.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          name: routeNames.globalSettingsEditGeneral,
          path: "edit/general",
          meta: {
            requiresAuth: true,
            permission: "tenant_storefronts.update",
          },
          component: () => import("./GlobalSettingsGeneralEditView.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          name: routeNames.globalSettingsEditMarketing,
          path: "edit/marketing",
          meta: {
            requiresAuth: true,
            permission: "tenant_storefronts.update",
          },
          component: () => import("./GlobalSettingsMarketingEditView.vue"),
        },
        { fullscreen: false }
      ),
    ],
  },
];
