<template>
  <AppSPASkeleton />
</template>

<script lang="ts" setup>
import { captureException } from "@sentry/vue";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import AppSPASkeleton from "@/components/skeletons/AppSPASkeleton.vue";
import { useQueryParameter } from "@/composables/use-query-value";
import { defaultLandingPage } from "@/router";
import { routeNames } from "@/router/route-names";
import { useAuthStore } from "@/store/auth-store";

const router = useRouter();
const stateQueryParam = useQueryParameter("state", "string");
const codeQueryParam = useQueryParameter("code", "string");
const errorQueryParam = useQueryParameter("error", "string");
const error = ref<string | undefined>(errorQueryParam.value);
const authStore = useAuthStore();

onMounted(() => {
  if (!errorQueryParam.value) {
    if (stateQueryParam.value && codeQueryParam.value) {
      error.value = undefined;
      authStore
        .fetchToken(stateQueryParam.value, codeQueryParam.value)
        .then(() => {
          router.replace({ name: defaultLandingPage });
        })
        .catch((responseError) => {
          captureException(responseError);
          error.value = responseError.message;
        });
    } else {
      router.replace({ name: routeNames.login });
    }
  }
});
</script>

<style scoped lang="scss"></style>
