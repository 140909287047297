import { uniqueId } from "lodash-es";

export function getAuthStateCode() {
  const code =
    Math.round(Math.random() * 1000000) + "-" + uniqueId() + "-" + Date.now();
  return code.trim();
}

export const maximumRefreshAttempts = 1;

export function getAuthSettings() {
  const domain = import.meta.env.VITE_APP_AUTH_DOMAIN as string;

  return {
    changePasswordUrl: import.meta.env
      .VITE_APP_AUTH_PASSWORD_CHANGE_URL as string,
    clientId: import.meta.env.VITE_APP_AUTH_CLIENT_ID as string,
    clientSecret: import.meta.env.VITE_APP_AUTH_CLIENT_SECRET as string,

    callbackUrl: import.meta.env.VITE_APP_AUTH_CALLBACK_URL as string,
    callbackLogoutUrl: import.meta.env
      .VITE_APP_AUTH_CALLBACK_LOGOUT_URL as string,

    checkUrl: import.meta.env.VITE_APP_AUTH_CHECK_URL as string,
    authorizeUrl: `${domain}/authorize`,
    tokenUrl: `${domain}/token`,
    logoutUrl: import.meta.env.VITE_APP_AUTH_LOGOUT_URL as string,
    scope: import.meta.env.VITE_APP_AUTH_SCOPE as string,
  };
}
