import { routeNames } from "@/router/route-names";
import type { RouteMetaConfig } from "@/types/router";
import { buildModalRoute } from "@/utils/modal-utils";

export const discountRoutes: RouteMetaConfig[] = [
  {
    path: "/discounts",
    name: routeNames.discountOverview,
    component: () => import("./views/DiscountsListView.vue"),
    meta: {
      requiresAuth: true,
      permission: "discounts.view",
      listFilterName: "discounts",
    },
    children: [
      buildModalRoute(
        {
          name: routeNames.discountCreate,
          path: "create",
          meta: {
            requiresAuth: true,
            permission: "discounts.create",
          },
          component: () => import("./views/DiscountsCreateView.vue"),
        },
        { fullscreen: true }
      ),
    ],
  },
  {
    path: "/discounts/:discountId(\\d+)",
    name: routeNames.discountDetails,
    meta: {
      requiresAuth: true,
      permission: "discounts.view",
    },
    component: () => import("./views/DiscountDetailView.vue"),
    children: [
      buildModalRoute(
        {
          name: routeNames.discountEditGeneral,
          path: "edit",
          meta: {
            requiresAuth: true,
            permission: "discounts.update",
          },
          component: () => import("./views/DiscountsEditView.vue"),
        },
        { fullscreen: false }
      ),
    ],
  },
];
