import type { Component } from "vue";
import { h } from "vue";
import type { IconProps, IconSet } from "vuetify";

import AppIconBacsDebit from "@/components/icons/AppIconBacsDebit.vue";
import AppIconBancontact from "@/components/icons/AppIconBancontact.vue";
import AppIconCard from "@/components/icons/AppIconCard.vue";
import AppIconCurrencyExchange from "@/components/icons/AppIconCurrencyExchange.vue";
import AppIconGiropay from "@/components/icons/AppIconGiropay.vue";
import AppIconIdeal from "@/components/icons/AppIconIdeal.vue";
import AppIconKlarna from "@/components/icons/AppIconKlarna.vue";
import AppIconLink from "@/components/icons/AppIconLink.vue";
import AppIconMonetizationOn from "@/components/icons/AppIconMonetizationOn.vue";
import AppIconPendingCancel from "@/components/icons/AppIconPendingCancel.vue";
import AppIconPendingPayment from "@/components/icons/AppIconPendingPayment.vue";
import AppIconSepa from "@/components/icons/AppIconSepa.vue";
import AppIconSofort from "@/components/icons/AppIconSofort.vue";

const customSvgNameToComponent: Record<string, Component> = {
  card: AppIconCard,
  sepa_debit: AppIconSepa,
  sofort: AppIconSofort,
  bacs_debit: AppIconBacsDebit,
  klarna: AppIconKlarna,
  ideal: AppIconIdeal,
  giropay: AppIconGiropay,
  link: AppIconLink,
  bancontact: AppIconBancontact,
  currencyExchange: AppIconCurrencyExchange,
  monetization: AppIconMonetizationOn,
  pendingCancel: AppIconPendingCancel,
  pendingPayment: AppIconPendingPayment,
};

export const customIcons: IconSet = {
  component: (props: IconProps) => h(customSvgNameToComponent[props.icon]),
};
