import type { RouteLocationNamedRaw } from "vue-router";

import { modalSizes } from "@/config/constants";
import { routeNames } from "@/router/route-names";
import type { IPermission } from "@/types/permission-types";
import type { RouteMetaConfig } from "@/types/router";
import { buildModalRoute } from "@/utils/modal-utils";

export const bookingRoutes: RouteMetaConfig[] = [
  {
    path: "/bookings",
    name: routeNames.bookingOverview,
    component: () => import("@/modules/booking/views/BookingListView.vue"),
    meta: {
      requiresAuth: true,
      permission: "bookings.view",
      listFilterName: "bookings",
    },
    children: [
      buildModalRoute(
        {
          name: routeNames.bookingCreate,
          path: "create",
          component: () =>
            import("@/modules/booking/views/BookingCreateForm.vue"),
        },
        { fullscreen: true }
      ),
    ],
  },
  {
    path: "/bookings/:bookingId(\\d+)",
    name: routeNames.bookingDetail,
    component: () => import("@/modules/booking/views/BookingDetailView.vue"),
    meta: {
      requiresAuth: true,
      permission: "bookings.view",
    },
    children: [
      buildModalRoute(
        {
          name: routeNames.bookingAdjust,
          path: "adjust",
          meta: {
            permission: ["booking_adjustments.view"] as IPermission[],
          },
          component: () =>
            import("@/modules/adjust-booking/views/BookingAdjustView.vue"),
        },
        { fullscreen: true }
      ),
      buildModalRoute(
        {
          path: "edit/general",
          name: routeNames.bookingEditGeneral,
          meta: {
            requiresAuth: true,
            permission: "bookings.update",
          },
          component: () =>
            import("@/modules/booking/views/BookingEditGeneralForm.vue"),
        },
        { fullscreen: false, width: modalSizes.md }
      ),
    ],
  },
];

export const bookingRoutesFactory = {
  bookingOverview() {
    return {
      name: routeNames.bookingOverview,
    };
  },
  bookingCreate(options?: { customerId?: string | number }) {
    return {
      name: routeNames.bookingCreate,
      query: {
        customerId: options?.customerId,
      },
    };
  },
  bookingDetail(bookingId: string | number): RouteLocationNamedRaw {
    return {
      name: routeNames.bookingDetail,
      params: {
        bookingId,
      },
    };
  },
  bookingEditGeneral(bookingId: string | number): RouteLocationNamedRaw {
    return {
      name: routeNames.bookingEditGeneral,
      params: {
        bookingId,
      },
    };
  },
  bookingAdjust(bookingId: string | number): RouteLocationNamedRaw {
    return {
      name: routeNames.bookingAdjust,
      params: {
        bookingId,
      },
    };
  },
};
