import { routeNames } from "@/router/route-names";
import type { RouteMetaConfig } from "@/types/router";
import { buildModalRoute } from "@/utils/modal-utils";

export const routesLocation: RouteMetaConfig[] = [
  {
    path: "/locations",
    name: routeNames.locationOverview,
    component: () => import("./LocationListView.vue"),
    meta: {
      requiresAuth: true,
      permission: "locations.view",
      listFilterName: "locations",
    },
  },
  {
    path: "/locations/:id(\\d+)",
    name: routeNames.locationDetails,
    component: () => import("./LocationDetailView.vue"),
    meta: {
      requiresAuth: true,
      permission: "locations.view",
    },
    children: [
      buildModalRoute(
        {
          path: "edit/general",
          name: routeNames.locationEditGeneral,
          meta: {
            requiresAuth: true,
            permission: "locations.update",
          },
          component: () => import("./LocationEditGeneralForm.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          path: "edit/unit-settings",
          name: routeNames.locationEditUnitSettings,
          meta: {
            requiresAuth: true,
            permission: "locations.update",
          },
          component: () => import("./LocationEditUnitSettingsForm.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          path: "edit/billing",
          name: routeNames.locationEditBilling,
          meta: {
            requiresAuth: true,
            permission: "locations.update",
          },
          component: () => import("./LocationEditBillingForm.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          path: "edit/floors",
          name: routeNames.locationEditFloors,
          meta: {
            requiresAuth: true,
            permission: "locations.update",
          },
          component: () => import("./LocationEditFloorsForm.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          path: "edit/contracts",
          name: routeNames.locationEditContracts,
          meta: {
            requiresAuth: true,
            permission: "locations.update",
          },
          component: () => import("./LocationEditContractsForm.vue"),
        },
        { fullscreen: true }
      ),
    ],
  },
];
