import { isArray } from "lodash-es";
import { computed } from "vue";

import { useAuthStore } from "@/store/auth-store";
import { usePermissionStore } from "@/store/permission-store";
import type { IPermission } from "@/types/permission-types";
import { can } from "@/utils/acl-utils";

export function getPermissionArray(
  permission?: IPermission | IPermission[]
): IPermission[] {
  if (!permission) {
    return [];
  }

  return !isArray(permission) ? [permission] : permission;
}

export function useUser() {
  const authStore = useAuthStore();
  const user = computed(() => authStore.user || undefined);
  const permissionsStore = usePermissionStore();
  const permissions = computed(() => permissionsStore.permissions || []);

  return {
    user,
    permissions,
    id: computed(() => user.value?.id),
    firstName: computed(() => user.value?.firstname),
    fullName: computed(() => {
      if (!user.value) {
        return "Kinnovis";
      }
      return [user.value?.firstname, user.value?.lastname]
        .filter((name) => !!name)
        .join(" ");
    }),
    initials: computed(() => {
      if (!user.value) {
        return "KN";
      }
      return [user.value?.firstname?.charAt(0), user.value?.lastname.charAt(0)]
        .filter((name) => !!name)
        .join("");
    }),
    email: computed(() => {
      return user.value?.email || "office@kinnovis.com";
    }),
    tenantName: computed(() => user.value?.tenant_name),
    currency: computed(() => user.value?.currency),
    can(permission: IPermission | undefined | null) {
      if (!permission) {
        return true;
      }

      return can(permission || undefined);
    },
    canSome(permissionsToCheck?: IPermission | IPermission[]) {
      if (!permissionsToCheck) {
        return true;
      }

      return getPermissionArray(permissionsToCheck).some((perm) =>
        (permissions.value || []).includes(perm)
      );
    },
    canAll(permissionsToCheck?: IPermission | IPermission[]) {
      if (!permissionsToCheck) {
        return true;
      }

      return getPermissionArray(permissionsToCheck).every((perm) =>
        (permissions.value || []).includes(perm)
      );
    },
  };
}
