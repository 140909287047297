import { captureException } from "@sentry/vue";
import { merge, round } from "lodash-es";

export type IDimensions = {
  width: number;
  height: number;
};

export type IResizeOptions =
  | { width: number; height?: number }
  | { width?: number; height: number }
  | { width: number; height: number };

export const allowedFileTypes = ["image/jpeg", "image/jpg", "image/png"];

export function getRoundedDimension(val: number) {
  return round(val, 1);
}

export function getResizeOptionDefaults(): IResizeOptions {
  return { width: 1000, height: 1000 };
}

export function getDefaultOptions(): IUseImageResizeOptions {
  return {
    maxDimensions: getResizeOptionDefaults(),
    allowedFileTypes: [...allowedFileTypes],
    maxSize: 1024,
  };
}

export function getIdealDimensions(
  dimensions: IDimensions,
  maxDimensions: IResizeOptions
): IDimensions {
  const maxWidth = maxDimensions.width || Number.MAX_VALUE;
  const maxHeight = maxDimensions.height || Number.MAX_VALUE;
  const { width, height } = dimensions;

  if (width <= maxWidth && height <= maxHeight) {
    return dimensions;
  }

  const ratio = Math.min(maxWidth / width, maxHeight / height);
  return {
    width: getRoundedDimension(width * ratio),
    height: getRoundedDimension(height * ratio),
  };
}

export function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as string);
      };

      reader.onerror = (e) => reject(e);
      reader.readAsDataURL(file);
    } catch (error) {
      captureException(error);
      reject(error);
    }
  });
}

export function resizeBase64(
  input: string,
  options: IResizeOptions
): Promise<string> {
  return new Promise((resolve, reject) => {
    try {
      const image = new Image();
      image.onload = function () {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        if (!ctx) {
          reject("Could not get context");
          return;
        }

        const { width, height } = getIdealDimensions(image, options);
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
        const url = canvas.toDataURL("image/png");
        canvas.remove();
        resolve(url);
      };
      image.onerror = function (error) {
        captureException(error);
        reject(error);
      };
      image.src = input;
    } catch (error) {
      reject(error);
    }
  });
}

export function base64ToImage(base64: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = function () {
      resolve(image);
    };
    image.onerror = reject;
    image.src = base64;
  });
}

export function resize(
  file: File,
  options: IResizeOptions = getResizeOptionDefaults()
): Promise<string | undefined> {
  return fileToBase64(file).then((base64) => resizeBase64(base64, options));
}

export function isValidFileType(file: File, types: string[]) {
  return types.includes(file.type);
}

/**
 *
 * @param file the file object
 * @param size the size in kb
 */
export function isValidFileSize(file: File, size: number) {
  return file.size < size * 1024;
}

export interface IUseImageResizeOptions {
  maxDimensions?: IResizeOptions;
  allowedFileTypes?: string[];
  maxSize?: number;
}

export interface IFileProcessingError {
  error: string;
  params?: Record<string, string | number | string[]>;
}

export function isFileProcessingError(
  param: unknown
): param is IFileProcessingError {
  return !!param && !!(param as IFileProcessingError).error;
}

export function resizeImage(
  file: File,
  options: IUseImageResizeOptions = getDefaultOptions()
): Promise<string | undefined> {
  options = merge(getDefaultOptions(), options);

  return new Promise((resolve, reject) => {
    if (!isValidFileType(file, options.allowedFileTypes || [])) {
      return reject({
        error: "validation.invalidFileType",
        params: { fileTypes: options.allowedFileTypes?.join(", ") },
      } as IFileProcessingError);
    }

    if (!isValidFileSize(file, options.maxSize as number)) {
      return reject({
        error: "validation.invalidFileSize",
        params: { size: options.maxSize },
      } as IFileProcessingError);
    }

    resize(file, options.maxDimensions)
      .then(resolve)
      .catch(() =>
        reject({
          error: "validation.imageProcessingError",
        } as IFileProcessingError)
      );
  });
}

export function getDimension(width = 0, height?: number): IDimensions {
  return { width: width, height: height ?? width };
}

export function isOrientation(size: IDimensions) {
  if (size.width >= size.height) {
    return "landscape";
  }

  return "portrait";
}

/**
 * Fits `elementToResize` proportionally inside `boundingBox`.
 * @param elementToResize
 * @param boundingBox
 * @param resize if true it will scale elementToResize to fit the boundingBox if it is smaller than boundingBox
 */
export function fitProportional(
  elementToResize: IDimensions,
  boundingBox: IDimensions,
  resize = false
): IDimensions {
  if (
    [
      elementToResize.width,
      elementToResize.height,
      boundingBox.width,
      boundingBox.height,
    ].some((value) => value <= 0)
  ) {
    return getDimension();
  }

  const ratio = Math.min(
    boundingBox.width / elementToResize.width,
    boundingBox.height / elementToResize.height
  );

  const result = {
    width: elementToResize.width * ratio,
    height: elementToResize.height * ratio,
  };

  if (!resize) {
    return result;
  } else {
    const aspect = result.width / result.height;
    if (boundingBox.height < boundingBox.width) {
      return {
        width: boundingBox.height * aspect,
        height: boundingBox.height,
      };
    } else {
      return {
        width: boundingBox.width,
        height: boundingBox.width / aspect,
      };
    }
  }
}
