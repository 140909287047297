<template>
  <AppSPASkeleton />
</template>

<script lang="ts" setup>
import { computed, onMounted } from "vue";
import { useRouter } from "vue-router";

import AppSPASkeleton from "@/components/skeletons/AppSPASkeleton.vue";
import { routeNames } from "@/router/route-names";
import { useAuthStore } from "@/store/auth-store";
import { usePermissionStore } from "@/store/permission-store";

const router = useRouter();
const permissionStore = usePermissionStore();
const authStore = useAuthStore();

const isAuthenticated = computed(() => {
  return authStore.isAuthenticated && permissionStore.permissions;
});

onMounted(async () => {
  if (isAuthenticated.value) {
    await authStore.logout();
  } else {
    router.replace({ name: routeNames.login });
  }
});
</script>

<style scoped lang="scss"></style>
