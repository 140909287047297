<template>
  <div class="dialog-root">
    <slot />

    <div v-for="dialog in queue" :key="dialog.id">
      <component :is="dialog.component" v-bind="getProps(dialog)" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { TAnyDialog } from "./dialogs";
import { useDialogQueue } from "./dialogs";

const { queue } = useDialogQueue();

function getProps(dialogInstance: TAnyDialog) {
  return {
    ...(dialogInstance.props || {}),
    resolve: dialogInstance.resolve,
  };
}
</script>
