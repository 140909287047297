import { useLocalStorage } from "@vueuse/core";
import { defineStore } from "pinia";

import httpClient from "@/api/http-client";
import { authService } from "@/api/services/auth-service";
import type { IAuth, IBehalfAuth, IUser } from "@/api/types/auth-types";
import { apiVersion } from "@/config/constants";
import { usePermissionStore } from "@/store/permission-store";
import {
  getAuthSettings,
  getAuthStateCode,
  maximumRefreshAttempts,
} from "@/utils/auth-utils";

const authStateCode = useLocalStorage("auth-state-code", "");

export interface IAuthStoreModel {
  authAttempts: number;
  credentials: IAuth | null;
  user: IUser | null;
  isExpired: null | boolean;
  loggedInAsAdmin: null | boolean;
  authState:
    | null
    | "not-logged-in"
    | "logging-out"
    | "logged-in"
    | "logging-in"
    | "refreshing-token"
    | "logging-in-as-admin"
    | "undetermined";
}

export const useAuthStore = defineStore("auth", {
  state: () =>
    ({
      authAttempts: 0,
      user: null,
      credentials: null,
      authState: null,
      loggedInAsAdmin: false,
      isExpired: false,
    }) as IAuthStoreModel,
  getters: {
    isAuthenticated: (state) => !!state.credentials && !!state.user,
    currentTenant: (state) => state.user?.tenant_name,
    userId: (state) => state.user?.id,
    isLoggedIn: (state) =>
      state.credentials?.access_token &&
      !state.isExpired &&
      state.authState === "logged-in",
    isLoggingIn: (state) => state.authState === "logging-in",
    isRefreshingToken: (state) => state.authState === "refreshing-token",
    isLoggedOut: (state) => state.authState === "not-logged-in",
    isLoggingOut: (state) => state.authState === "logging-out",
    authHeader: (state) => {
      const headers: Record<string, string> = {};

      if (state.credentials?.access_token) {
        headers["Authorization"] = `Bearer ${state.credentials?.access_token}`;
      }

      return headers;
    },
    getToken: (state) => state.credentials?.access_token,
    hasRefreshToken: (state) => state.credentials?.refresh_token,
  },
  persist: true,
  actions: {
    async setCredentials(payload: IAuth) {
      if (payload.access_token && payload.expires_in > 0) {
        this.credentials = payload;
      } else {
        return this.logout();
      }
    },
    setUserData(user: IUser) {
      this.user = user;
    },
    /**
     * Initializes the session. Must be called before the app launches.
     * Ideally called before the router is set up.
     */
    async initializeSession() {
      if (!this.credentials?.access_token) {
        // User has no local session. Reset everything to "not logged in".
        this.credentials = null;
        this.isExpired = null;
        this.authState = "not-logged-in";
        this.loggedInAsAdmin = false;

        return Promise.resolve({});
      }

      // If token is available, perform a request against a fast secured API endpoint.
      // If it responds with 200 the access token is valid and we can proceed to the app.
      const headers = this.authHeader;

      const { checkUrl } = getAuthSettings();

      return httpClient
        .get(checkUrl, {
          headers,
        })
        .then(() => {
          // All fine. Proceed to app.
          this.authState = "logged-in";
          return authService.updateClientData();
        })
        .catch(() => {
          this.authState = "not-logged-in";
          return Promise.reject({});
        });
    },

    refreshToken() {
      const currentRefreshToken = this.credentials?.refresh_token ?? null;
      if (!currentRefreshToken || this.loggedInAsAdmin) {
        return this.logout();
      }

      this.incrementTokenRefreshAttempts();

      // Try a token refresh
      this.authState = "refreshing-token";
      return authService
        .refreshToken(currentRefreshToken)
        .then((authResponse: IAuth) => {
          return this.setAuthResponse(authResponse);
        })
        .catch(() => {
          return this.logout();
        });
    },

    incrementTokenRefreshAttempts() {
      if (this.isMaximumRefreshAttemptsReached()) {
        throw new Error(
          `Maximum refresh attempts of ${maximumRefreshAttempts} reached!`
        );
      }

      this.authAttempts = this.authAttempts + 1;
    },

    isMaximumRefreshAttemptsReached() {
      return this.authAttempts >= maximumRefreshAttempts;
    },

    /**
     * Gets the auth token from the given OAuth code after the redirect
     * @param stateCode the local stored auth state code. It must match the one in the redirect query parameter
     * @param authCode the auth code from the redirect query parameter
     */
    fetchToken(stateCode: string, authCode: string) {
      // only if the locally stored code and the code from the redirect query parameter match, we can proceed.
      if (authStateCode.value && stateCode === authStateCode.value) {
        this.authState = "logging-in";
        return authService
          .getToken(authCode)
          .then((authResponse: IAuth) => {
            return this.setAuthResponse(authResponse);
          })
          .catch(() => {
            this.authState = "not-logged-in";
          });
      } else {
        // state code does not match the one of the session. The login attempt is invalid.
        authStateCode.value = "";
        this.$reset();
        this.authState = "not-logged-in";
        this.login();
        return Promise.reject({});
      }
    },

    /**
     * Redirects the user to the SSO login page.
     */
    login() {
      this.$reset();
      this.authState = "logging-in";
      this.loggedInAsAdmin = false;
      authStateCode.value = getAuthStateCode();
      window.location.href = authService.getLoginRedirectUrl(
        authStateCode.value
      );
    },

    /**
     * Redirects the user to the SSO login page.
     */
    changePassword() {
      if (this.user?.email && !this.loggedInAsAdmin) {
        window.location.href = authService.getChangePasswordUrl(
          this.user.email as string
        );
      }
    },

    /**
     * Redirects the user to the SSO login page.
     */
    async adminLogin(email: string) {
      this.authState = "logging-in-as-admin";
      return httpClient
        .post<IBehalfAuth>(
          `${import.meta.env.VITE_APP_API_URL}/${apiVersion}/admin/login`,
          {
            email: email,
          }
        )
        .then((data) => {
          const expiresIn = Math.round(
            (new Date(data.data.token.expires_at).getTime() - Date.now()) / 1000
          );

          const authResponse: IAuth = {
            expires_in: expiresIn,
            access_token: data.data.accessToken,
            refresh_token: "",
            token_type: "Bearer",
          };

          this.loggedInAsAdmin = true;
          this.authState = "logged-in";
          return this.setAuthResponse(authResponse);
        })
        .then(() => {
          // login done
          return;
        });
    },

    /**
     * logs out the user by sending a logout request and resetting the local session
     */
    async logout() {
      const currentAccessToken = this.credentials?.access_token ?? "";

      if (currentAccessToken === "") {
        return;
      }

      const permissionStore = usePermissionStore();

      this.authAttempts = 0;
      this.credentials = null;
      this.user = null;
      this.authState = "logging-out";
      this.loggedInAsAdmin = false;

      await permissionStore.reset();

      window.location.href =
        authService.getLogoutRedirectUrl(currentAccessToken);
    },

    /**
     * Sets the auth response and sets the user as logged in.
     */
    setAuthResponse(authResponse: IAuth) {
      this.credentials = authResponse;

      if (authResponse) {
        this.authState = "logged-in";
      }

      return authService.updateClientData();
    },
  },
});
