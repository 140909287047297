<template>
  <div class="flex grow" data-test-id="layout-default">
    <v-app-bar :elevation="0">
      <v-app-bar-nav-icon
        data-test-id="menu-toggle-button"
        @click.stop="openMenu = !openMenu"
      />
      <router-link data-test-id="menu-logo" class="logo-wrapper" to="/">
        <img alt="" :src="logo" width="120" />
      </router-link>
      <v-spacer />
      <AppUserAvatarMenu data-test-id="menu-avatar" />
    </v-app-bar>

    <AppMainMenu v-model:open="openMenu" />

    <v-main app class="content">
      <router-view :key="$route.path" />
    </v-main>

    <v-footer app clipped class="back">
      <div class="flex justify-between gap-2 w-full text-xxs">
        <div>
          Kinnovis GmbH
          <v-icon size="x-small">mdi-copyright</v-icon>
          {{ currentYear }}
        </div>
        <div v-if="version">v{{ version }}</div>
      </div>
    </v-footer>
  </div>
</template>

<script lang="ts" setup>
import dayjs from "dayjs";
import { ref } from "vue";

import logo from "@/assets/logos/logo-dark-blue.png";
import AppUserAvatarMenu from "@/components/layout/AppUserAvatarMenu.vue";
import { useAppInformation } from "@/composables/use-app-verison";
import { useManagerFeature } from "@/composables/use-manager-feature";
import AppMainMenu from "@/modules/app/components/AppMainMenu.vue";

const currentYear = ref(dayjs().year());
const openMenu = ref(true);
const { availableFeatures } = useManagerFeature();

// eslint-disable-next-line no-console
console.log("👉 Features", JSON.parse(JSON.stringify(availableFeatures.value)));

const { version } = useAppInformation();
</script>

<style lang="scss">
* {
  letter-spacing: 0.04rem;
}

.v-app-bar.v-toolbar {
  @apply bg-white/40 border-b border-app-separator backdrop-blur-sm;
}

.v-footer {
  @apply border-t border-app-separator;
}

.logo-wrapper {
  display: block;
  line-height: 0;
}
</style>
