export const grid = {
  col12: { cols: 12 },
  col6: {
    cols: 12,
    sm: 6,
  },
  col4: {
    cols: 12,
    sm: 6,
    md: 4,
  },
  col3: {
    cols: 12,
    sm: 4,
    md: 3,
  },
  col2: {
    cols: 12,
    sm: 3,
    md: 2,
  },
  detailCards: {
    cols: 12,
    sm: 6,
  },
  charts: {
    cols: 12,
    lg: 6,
  },
  dailyStatistics: {
    cols: 12,
    sm: 6,
    lg: 4,
  },
  collapseGroup: {
    sm: 6,
    md: 4,
    xl: 3,
    cols: 12,
  },
  facilityMapFilters: {
    cols: 12,
    lg: 6,
  },
  radioButtonContent: {
    cols: 12,
    sm: 6,
    md: 5,
    lg: 4,
  },
};
