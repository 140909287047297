<template>
  <span class="ui-icon">
    <slot />
  </span>
</template>

<script lang="ts" setup></script>

<style scoped lang="scss">
.ui-icon {
  align-items: center;
  display: inline-flex;
  height: 1em;
  justify-content: center;
  letter-spacing: normal;
  line-height: 1;
  position: relative;
  text-indent: 0;
  text-align: center;
  user-select: none;
  vertical-align: middle;
  width: 1em;
  min-width: 1em;
  fill: currentColor;
}
</style>
