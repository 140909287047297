import type { AxiosInstance } from "axios";

import type {
  ILegacyFilterItem,
  IPaginatedResponse,
  IRequestFilterOptions,
  IResponse,
} from "@/api/types/general-types";
import { apiVersion } from "@/config/constants";
import { getApiBasePath } from "@/utils/request-utils";

import httpClient, { silentHttpClient } from "./http-client";

// This class is a first step of changing of http system. Not finished yet
export default class BaseProxy<
  ListResponse,
  ShowResponse = ListResponse,
  CreateInput = Record<string, unknown>,
  CreateResponse = ShowResponse,
  UpdateInput = Record<string, unknown>,
  UpdateResponse = ShowResponse,
> {
  // requests will be performed on this entity
  protected entity: string;
  // base path to the entity
  protected basePath: string;
  protected httpClient: AxiosInstance;
  protected silentHttpClient: AxiosInstance;
  protected version = apiVersion;

  constructor(entity: string, version: string = apiVersion) {
    this.entity = entity;
    this.basePath = `${getApiBasePath()}/${this.entity}`;
    this.httpClient = httpClient;
    this.silentHttpClient = silentHttpClient;
    this.version = version;
  }

  /**
   * Get all records of a specific entity
   * @returns Promise
   */
  fetchAll(
    filter?: IRequestFilterOptions
  ): Promise<IPaginatedResponse<ListResponse>> {
    return this.httpClient.get(this.basePath, { params: filter });
  }

  /**
   * Get all records of a specific entity
   * @param id the id of the entity
   * @returns Promise
   */
  fetch(id: string | number): Promise<IResponse<ShowResponse>> {
    return this.httpClient.get(`${this.basePath}/${id}`);
  }

  /**
   * Create a new entity
   * @param data the data of the entity
   * @returns Promise
   */
  create(data: CreateInput): Promise<IResponse<CreateResponse>> {
    return this.httpClient.post(`${this.basePath}`, data);
  }

  /**
   * Update a given entity
   * @param id the id of the entity
   * @param data the data of the entity
   * @returns Promise
   */
  update(
    id: string | number,
    data: UpdateInput
  ): Promise<IResponse<UpdateResponse>> {
    return this.httpClient.put(`${this.basePath}/${id}`, data);
  }

  /**
   * Patches the given element. Only the given properties will be updated.
   */
  patch(
    id: string | number,
    data: Partial<UpdateInput>
  ): Promise<IResponse<UpdateResponse>> {
    return this.httpClient.patch(`${this.basePath}/${id}`, data);
  }

  /**
   * Delete a given entity
   * @param id the id of the entity
   * @returns Promise
   */
  delete(id: string | number): Promise<void> {
    return this.httpClient.delete(`${this.basePath}/${id}`);
  }

  fetchFilters(): Promise<IResponse<ILegacyFilterItem[]>> {
    return this.httpClient.get(`${this.basePath}/filter`);
  }
}

export function listRequest<T>(
  entity: string,
  params?: IRequestFilterOptions
): Promise<IPaginatedResponse<T>> {
  const basePath = `${getApiBasePath()}/${entity}`;
  return httpClient.get(basePath, { params });
}

export class ApiService {
  protected entity: string;
  // base path to the entity
  protected basePath: string;
  protected httpClient: AxiosInstance;
  protected silentHttpClient: AxiosInstance;
  protected version = apiVersion;

  constructor(entity: string, version: string = apiVersion) {
    this.entity = entity;
    this.basePath = `${getApiBasePath()}/${this.entity}`;
    this.httpClient = httpClient;
    this.silentHttpClient = silentHttpClient;
    this.version = version;
  }
}
