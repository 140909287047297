import { routeNames } from "@/router/route-names";
import type { RouteMetaConfig } from "@/types/router";
import { buildModalRoute } from "@/utils/modal-utils";

export const routesTenant: RouteMetaConfig[] = [
  {
    path: "/tenants",
    name: routeNames.tenantsOverview,
    component: () => import("./TenantListView.vue"),
    meta: {
      requiresAuth: true,
      permission: "tenants.view",
    },
    children: [
      buildModalRoute(
        {
          name: routeNames.tenantCreate,
          path: "create",
          meta: {
            requiresAuth: true,
            permission: "tenants.create",
          },
          component: () => import("./TenantCreateView.vue"),
        },
        { fullscreen: true }
      ),
    ],
  },
  {
    path: "/tenants/:id(\\d+)",
    name: routeNames.tenantDetails,
    component: () => import("./TenantDetailView.vue"),
    meta: {
      requiresAuth: true,
      permission: "tenants.view",
    },
    children: [
      buildModalRoute(
        {
          path: "edit/general",
          name: routeNames.tenantEditGeneral,
          meta: {
            requiresAuth: true,
            permission: "tenants.update",
          },
          component: () => import("./TenantEditGeneralForm.vue"),
        },
        { fullscreen: false }
      ),
    ],
  },
];
