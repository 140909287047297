import type { ICustomerDetail } from "@/api/types/customer-types";

const makeMonthNumber = (number: number): string => {
  const monthNumber = number + 1;
  const prepend = monthNumber < 10 ? "0" : "";
  return prepend + monthNumber;
};

/**
 * Function to prepare the value for filter and remove values after `_` char to avoid duplicates
 * @param filterValue is origin value that will be mutated
 * */
const clearStringBeforeFilter = (filterValue: string): string => {
  const filter = filterValue ? filterValue.toString().toLowerCase() : "";
  return filter.substring(filter.indexOf("_") + 1);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const buildFilterValueString = (value: any): string => {
  if (Array.isArray(value)) {
    value = value.map((item: string) => clearStringBeforeFilter(item));
  }
  return clearStringBeforeFilter(value);
};

export function getFullName(
  item?: Partial<Pick<ICustomerDetail, "firstname" | "lastname">>
) {
  const fullName = [item?.firstname, item?.lastname]
    .map((name) => name?.trim())
    .filter((name) => name)
    .join(" ");

  return fullName ? fullName : "-";
}

export function extractNumber(str: string) {
  const regex = /(\d+)/; // Regular expression to match a sequence of digits
  const match = str.match(regex);

  return match ? +match[0] : -1;
}

/**
 * Extracts the item and field indices from the given string
 * Example input: '0.0.item_distinct'
 * Returns: [0, 0]
 */
export function extractIndices(str: string): [number, number] {
  const regex = /^(\d+)\.(\d+)/;
  const match = str.match(regex);

  return match ? [+match[1], +match[2]] : [-1, -1];
}

export { buildFilterValueString, makeMonthNumber };
