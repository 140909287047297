<template>
  <UIMenu>
    <template #activator="{ props: activatorProps }">
      <v-btn
        v-bind="activatorProps"
        :disabled="isLoading"
        :loading="isLoading"
        color="secondary"
        class="text-primary"
        variant="flat"
      >
        {{ currentLanguage.toUpperCase() }}
        <v-icon class="ml-2" size="small">mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-list class="w-full">
      <v-list-item
        v-for="(item, index) in userInterfaceLanguages"
        :key="index"
        link
        @click="handleLanguageChange(item)"
      >
        <v-list-item-title>{{ item.toUpperCase() }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </UIMenu>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";

import { useLanguages } from "@/composables/use-languages";
import UIMenu from "@/modules/ui-menu/components/UIMenu.vue";
import { useUserSettingsStore } from "@/store/user-settings-store";

const { userInterfaceLanguages } = useLanguages();
const userSettingsStore = useUserSettingsStore();
const currentLanguage = computed(() => userSettingsStore.locale);

const isLoading = ref(false);

function handleLanguageChange(newLocale: string) {
  isLoading.value = true;
  userSettingsStore.changeLanguage(newLocale);

  setTimeout(() => {
    isLoading.value = false;
  }, 1500);
}
</script>

<style scoped lang="scss"></style>
