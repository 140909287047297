export const measurements = ["metric", "imperial"];

export const rangeFilterNames = ["created_at", "issue_date"];

export const apiVersion = import.meta.env.VITE_APP_DEFAULT_API_VERSION;

export const modalSizes = {
  xl: "1200px",
  lg: "800px",
  md: "500px",
  sm: "290px",
  /**
   * Allow for dynamic sizing inside the modal
   */
  unset: "unset",
};

export const noteConfig = {
  maxLength: 1500,
};

export const unpaginatedListLimit = 10000;
