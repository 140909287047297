import { routeNames } from "@/router/route-names";
import type { RouteMetaConfig } from "@/types/router";
import { buildModalRoute } from "@/utils/modal-utils";

import { facilityMapPermissions } from "./facility-map-constants";

export const facilityMapRoutes: RouteMetaConfig[] = [
  {
    name: routeNames.facilityMapOverview,
    path: "/facility-map/:facilityMapId(\\d+)?",
    component: () => import("./views/FacilityMapListView.vue"),
    meta: {
      requiresAuth: true,
      permission: facilityMapPermissions.view,
    },
    children: [
      buildModalRoute(
        {
          name: routeNames.facilityMapEditorCreateBooking,
          path: "booking/create",
          component: () =>
            import("@/modules/booking/views/BookingCreateForm.vue"),
        },
        { fullscreen: true }
      ),
      buildModalRoute(
        {
          name: routeNames.facilityMapEditorAdjustBooking,
          path: "booking/:bookingId(\\d+)/adjust",
          component: () =>
            import("@/modules/adjust-booking/views/BookingAdjustView.vue"),
        },
        { fullscreen: true }
      ),
      buildModalRoute(
        {
          name: routeNames.facilityMapEditor,
          path: ":mapId/edit",
          meta: {
            requiresAuth: true,
            permission: facilityMapPermissions.create,
          },
          component: () => import("./views/FacilityMapEditorView.vue"),
        },
        { fullscreen: true }
      ),
    ],
  },
];

export const facilityMapRoutesFactory = {
  adjustBooking(bookingId: number, redirectTo: string, facilityMapId?: number) {
    return {
      name: routeNames.facilityMapEditorAdjustBooking,
      params: {
        facilityMapId,
        bookingId,
      },
      query: {
        redirectTo,
      },
    };
  },
};
