import { type AxiosRequestConfig, CanceledError } from "axios";
import { computed, type ComputedRef, ref } from "vue";

import { silentHttpClient } from "@/api/http-client";
import { apiVersion } from "@/config/constants";

export function getApiBasePath() {
  return `/${apiVersion}`;
}

export interface ICancelRequestOptionsType
  extends Pick<
    AxiosRequestConfig,
    "url" | "data" | "params" | "method" | "headers"
  > {
  ignoreCancelError?: boolean;
}

export function isCancelError<T = unknown>(e: unknown): e is CanceledError<T> {
  return e instanceof CanceledError;
}

export function getCancelRequest<TData>(options: ICancelRequestOptionsType): {
  cancel(): void;
  request: Promise<TData>;
  isCancelled: ComputedRef<boolean>;
} {
  const controller: AbortController = new AbortController();
  const isCancelled = ref(false);

  return {
    request: silentHttpClient
      .request({
        ...options,
        signal: controller?.signal,
      })
      .then((data) => {
        return data as TData;
      })
      .catch((e) => {
        if (isCancelError(e)) {
          isCancelled.value = true;
        }

        return Promise.reject(e);
      }),
    cancel() {
      controller?.abort();
    },
    isCancelled: computed(() => isCancelled.value),
  };
}
