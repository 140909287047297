import { isArray, isEqual, random, uniq } from "lodash-es";

/**
 * Generates an array with the given length.
 * If an offset is defined each value will be incremented by it.
 *
 * @return an array containing the index as value,
 * e.g.:
 * length = 3 -> [0, 1, 2],
 * length = 3, offset = 1 -> [1, 2, 3]
 */
export function arrayWithLength(length: number, offset = 0): number[] {
  return Array.from(Array(Math.floor(Math.max(length, 0)))).map(
    (_, index) => index + offset
  );
}

/**
 * converts the given value to an array if it is not already an array
 */
export function toArray<T>(data: T | T[]): T[] {
  if (!isArray(data)) {
    return [data];
  }

  return data;
}

/**
 * Takes an array of values and another array.
 * Checks if all values of array1 are contained in array2.
 * Will remove any values from array1 that are not in array2.
 * Does not mutate anything but returns a new array if some values were removed, otherwise the same array.
 * @param selectedIds
 * @param allowedIds
 */
export function getConstrainedValues<T = number>(
  selectedIds: T[],
  allowedIds: T[] | null | undefined
): T[] {
  if (selectedIds && allowedIds) {
    const filteredList = selectedIds.filter((id) => allowedIds.includes(id));

    if (!isEqual(selectedIds, filteredList)) {
      return filteredList;
    }
  }

  return selectedIds;
}

/**
 * returns a flat and trimmed list of string tokens
 * example:
 * Given: `['a', ' b ', 'c d e', 'f]` --> Return: ['a', 'b', 'c', 'd', 'e', 'f']
 * @param tokens
 */
export function getFlatTokens(tokens: string[]): string[] {
  return uniq(
    (tokens || [])
      .map((token) => token.trim().split(" "))
      .flat()
      .filter((empty) => !!empty)
      .map((token) => token.trim())
  );
}

export function removeUndefined<T>(array: (T | undefined)[]): T[] {
  return array.filter((v) => v !== undefined) as T[];
}

export function getRandomArrayItem<T>(array: T[]): T | undefined {
  if (array.length) {
    return array[random(0, array.length - 1)];
  }

  return undefined;
}
