import { useStorage } from "@vueuse/core";
import type { Ref } from "vue";
import { computed, reactive, ref, watch } from "vue";

export type IManagerFeature = "facilityMap";

export function useManagerFeature() {
  // Add any new feature into this array
  const _allFeatures: IManagerFeature[] = [];

  // cleanup: load all keys and filter by feature. check if the feature is in the _allFeatures list.
  // if not, remove it from the local storage
  Object.keys(localStorage || {})
    .filter(
      (key) =>
        key.startsWith("feature.") &&
        !_allFeatures.includes(key.replace("feature.", "") as IManagerFeature)
    )
    .forEach((key) => {
      localStorage?.removeItem(key);
    });

  localStorage?.removeItem("features.hidden");

  const features = reactive<Record<IManagerFeature, boolean>>({
    facilityMap: false,
  });
  const refs: { feature: IManagerFeature; isEnabled: Ref<boolean> }[] = [];
  const hiddenFeatures: Ref<IManagerFeature[]> = ref(_allFeatures);

  for (const feature of _allFeatures) {
    const featureRef = useStorage<boolean>(`feature.${feature}`, false);
    refs.push({ feature, isEnabled: featureRef });
  }

  watch(
    refs.map((r) => r.isEnabled),
    () => {
      hiddenFeatures.value = [];
      refs.forEach((r) => {
        features[r.feature] = r.isEnabled.value;

        if (r.isEnabled) {
          hiddenFeatures.value.push(r.feature);
        }
      });
    },
    {
      immediate: true,
    }
  );

  return {
    isFeatureDisabled(feature?: IManagerFeature) {
      if (import.meta.env.VITE_APP_ENV === "test") {
        return false;
      }

      if (feature) {
        return features[feature] === false;
      }

      return false;
    },
    availableFeatures: computed(() => _allFeatures),
    hiddenFeatures: computed(() => {
      if (import.meta.env.VITE_APP_ENV === "test") {
        return [];
      }

      return hiddenFeatures.value;
    }),
  };
}
