import { isString, toNumber } from "lodash-es";

export function toBoolean(
  value: number | string | null | undefined
): undefined | boolean {
  if (value == null) {
    return undefined;
  }

  if (isString(value)) {
    if (value === "true") {
      return true;
    }

    if (value === "false") {
      return false;
    }
  }

  const num = toNumber(value);

  if (num != null) {
    return !!num;
  } else {
    return !!value;
  }
}
