<template>
  <div class="flex gap-4 justify-end mt-5" data-test-id="modal-body">
    <UIButton
      variant="black-clear"
      :disabled="disabled || loading"
      type="button"
      :data-test-id="dataTestIdCancel"
      @click="emit('click:cancel', $event)"
      >{{ cancelText || t("generic.cancel") }}
    </UIButton>

    <UIButton
      :disabled="disabled || disabledSubmit"
      :loading="loading"
      :form="form"
      :type="type"
      :variant="variant"
      depressed
      :data-test-id="dataTestIdSubmit"
      @click="emit('click:submit', $event)"
      >{{ submitText || t("generic.save") }}
    </UIButton>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import type { TranslateResult } from "vue-i18n";

import { useTranslate } from "@/composables/use-translate";
import UIButton from "@/modules/ui/buttons/components/UIButton.vue";
import type { ITableRowConfirmData } from "@/types/action-menu-types";

const { t } = useTranslate();

interface Props {
  color?: ITableRowConfirmData["color"];
  disabled?: boolean;
  disabledSubmit?: boolean;
  loading?: boolean;
  form?: string;
  cancelText?: string | TranslateResult;
  submitText?: string | TranslateResult;
  type?: "submit" | "button";
  noMargin?: boolean;
  dataTestIdCancel?: string;
  dataTestIdSubmit?: string;
}

const props = withDefaults(defineProps<Props>(), {
  color: "primary",
  form: undefined,
  cancelText: undefined,
  disabled: false,
  loading: false,
  submitText: undefined,
  disabledSubmit: undefined,
  type: "submit",
  noMargin: false,
  dataTestIdCancel: "close-button",
  dataTestIdSubmit: "submit-button",
});

const emit = defineEmits<{
  (e: "click:cancel", data: Event): void;
  (e: "click:submit", data: Event): void;
}>();

const variant = computed(() => {
  switch (props.color) {
    case "error":
      return "danger-full";
    case "warning":
      return "warning-full";
    case "primary":
    default:
      return "primary";
  }
});
</script>
