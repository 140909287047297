import { routeNames } from "@/router/route-names";
import type { RouteMetaConfig } from "@/types/router";

export const dashboardRoutes: RouteMetaConfig[] = [
  {
    path: "/dashboard",
    name: routeNames.dashboardOverview,
    component: () => import("./views/DashboardView.vue"),
    meta: {
      requiresAuth: true,
      permission: "dashboard.view",
    },
  },
];
