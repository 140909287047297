import "dayjs/locale/de";
import "dayjs/locale/fr";
import "dayjs/locale/pl";
import "dayjs/locale/es";
import "dayjs/locale/en-gb";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);

export function setDayjsLocale(local: string) {
  // we hardcode it to en-gb because we want to format the dates in UK format
  const locale = local === "en" ? "en-gb" : local;
  dayjs.locale(locale);
}
