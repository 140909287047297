import { defineStore } from "pinia";

export const useNavigationDrawerStore = defineStore(
  "navigation-drawer",
  () => {
    return {
      width: 256,
    };
  },
  { persist: true }
);
