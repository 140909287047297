import type { INotificationType } from "./snackbar-types";

export const snackbarDurations: Record<INotificationType, number> = {
  success: 1500,
  error: 4000,
  info: 4000,
};
export const snackbarMessages: Record<INotificationType, string> = {
  success: "baseSnackBar.successDefault",
  error: "baseSnackBar.errorDefault",
  info: "baseSnackBar.infoDefault",
};
