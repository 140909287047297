import AuthAttemptPage from "@/modules/auth/views/AuthAttemptPage.vue";
import AuthPage from "@/modules/auth/views/AuthPage.vue";
import LoginPage from "@/modules/auth/views/LoginPage.vue";
import LogoutPage from "@/modules/auth/views/LogoutPage.vue";
import { routeNames } from "@/router/route-names";
import type { RouteMetaConfig } from "@/types/router";

export const authRoutes: RouteMetaConfig[] = [
  {
    path: "/login",
    name: routeNames.login,
    component: LoginPage,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/auth",
    name: routeNames.auth,
    component: AuthPage,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/auth-attempt",
    name: routeNames.authAttempt,
    component: AuthAttemptPage,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/logout",
    name: routeNames.logout,
    component: LogoutPage,
    meta: {
      requiresAuth: false,
    },
  },
];
