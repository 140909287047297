import { useQuery } from "@tanstack/vue-query";

import { getSetup } from "@/api/services/setup-service";

export function useSetupQuery() {
  return useQuery({
    queryKey: ["setup"],
    queryFn: () => getSetup(),
  });
}
