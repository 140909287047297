<template>
  <AppSPASkeleton />
</template>

<script lang="ts" setup>
import { onMounted } from "vue";

import AppSPASkeleton from "@/components/skeletons/AppSPASkeleton.vue";
import { useAuthStore } from "@/store/auth-store";

const authStore = useAuthStore();

onMounted(() => {
  authStore.login();
});
</script>

<style scoped lang="scss"></style>
