import { routeNames } from "@/router/route-names";
import type { RouteMetaConfig } from "@/types/router";
import { buildModalRoute } from "@/utils/modal-utils";

export const unitsRoutes: RouteMetaConfig[] = [
  {
    path: "/units",
    name: routeNames.unitOverview,
    component: () => import("./views/UnitListView.vue"),
    meta: {
      requiresAuth: true,
      permission: "units.view",
      listFilterName: "units",
    },
    children: [
      buildModalRoute(
        {
          name: routeNames.unitCreate,
          path: "create",
          meta: {
            requiresAuth: true,
            permission: "units.create",
          },
          component: () => import("./views/UnitCreateView.vue"),
        },
        { fullscreen: true }
      ),
    ],
  },
  {
    path: "/units/:id(\\d+)",
    name: routeNames.unitDetails,
    component: () => import("./views/UnitDetailView.vue"),
    meta: {
      requiresAuth: true,
      permission: "units.view",
    },
    children: [
      buildModalRoute(
        {
          path: "edit/general",
          name: routeNames.unitEditGeneral,
          meta: {
            requiresAuth: true,
            permission: "units.update",
          },
          component: () => import("./views/UnitEditGeneralForm.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          path: "edit/measurements",
          name: routeNames.unitEditMeasurements,
          meta: {
            requiresAuth: true,
            permission: "units.update",
          },
          component: () => import("./views/UnitEditMeasurementsForm.vue"),
        },
        { fullscreen: false }
      ),
    ],
  },
];

export const unitRouteFactory = {
  unitDetails(unitId: number | string) {
    return {
      name: routeNames.unitDetails,
      params: {
        id: unitId,
      },
    };
  },
};
