import * as Sentry from "@sentry/vue";
import type { App } from "vue";
import type { Router } from "vue-router";

import * as packageJson from "@@/package.json";

export function setupSentry(app: App, router: Router) {
  if (!import.meta.env.DEV) {
    Sentry.init({
      app,
      dsn: import.meta.env.VITE_APP_SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
      ],
      release: packageJson.version,
      environment: import.meta.env.VITE_APP_ENV,
      logErrors: true,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
      tracePropagationTargets: ["localhost:3000", "kinnovis.com", /^\//],
      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }
}
