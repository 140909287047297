<template>
  <component :is="'h' + level" :class="classes">
    <slot />
  </component>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import type {
  IHeadingLevel,
  IHeadingVariant,
} from "@/modules/ui/general/ui-heading-types";

interface Props {
  level: IHeadingLevel;
  variant: IHeadingVariant;
}

const props = withDefaults(defineProps<Props>(), {
  level: 1,
  variant: "default",
});

const sizeMap: Record<IHeadingLevel, string> = {
  "1": "text-2xl",
  "2": "text-xl",
  "3": "text-base",
  "4": "text-sm",
  "5": "text-xs",
  "6": "text-xxs",
};

const classes = computed(() => {
  const classes: string[] = ["leading-tight"];

  switch (props.variant) {
    case "default":
      classes.push("font-medium normal-case");
      break;
    case "bold":
      classes.push("font-bold normal-case");
      break;
    case "uppercase":
      classes.push("font-medium uppercase tracking-wider");
      break;
  }

  classes.push(sizeMap[(props.level + "") as IHeadingLevel]);

  return classes;
});
</script>

<style scoped lang="scss"></style>
