import { routeNames } from "@/router/route-names";
import type { RouteMetaConfig } from "@/types/router";

export const pdfRendererRoutes: RouteMetaConfig[] = [
  {
    name: routeNames.pdfRendererReport,
    path: "/pdf/report/:code",
    component: () =>
      import("@/modules/pdf-renderer/views/PDFReportRenderer.vue"),
    meta: {
      requiresAuth: false,
      layout: "blank",
    },
  },

  {
    name: routeNames.pdfRendererReportTest,
    path: "/pdf/report-test",
    component: () =>
      import("@/modules/pdf-renderer/views/PDFReportRendererTest.vue"),
    meta: {
      requiresAuth: false,
      layout: "blank",
      env: "development",
    },
    children: [
      {
        name: routeNames.pdfRendererReportTestRenderer,
        path: ":code",
        component: () =>
          import(
            "@/modules/pdf-renderer/views/PDFReportRendererTestRenderer.vue"
          ),
      },
    ],
  },
];
