import { useStorage } from "@vueuse/core";
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";

import { userService } from "@/api/services/user-service";
import { useTranslate } from "@/composables/use-translate";
import { useUser } from "@/composables/use-user";
import { fallbackLocale } from "@/config/locale-config";
import { setDayjsLocale } from "@/plugins/dayjs";
import { useAuthStore } from "@/store/auth-store";
import { localStorageKey } from "@/utils/user-settings-utils";

export const useUserSettingsStore = defineStore("user-settings", () => {
  const { id } = useUser();
  const router = useRouter();
  const authStore = useAuthStore();
  const serverSideLocale = computed(() =>
    (authStore.user?.language_id || fallbackLocale)?.toLowerCase()
  );
  const { locale } = useTranslate();

  const persistedLocale = useStorage(localStorageKey, serverSideLocale.value);

  watch(
    serverSideLocale,
    (language) => {
      locale.value = language;
      persistedLocale.value = language;
    },
    { immediate: true }
  );

  const reloadingAfterLanguageChange = ref(false);

  watch(
    locale,
    () => {
      persistedLocale.value = locale.value;
      setDayjsLocale(locale.value);
    },
    { immediate: true }
  );

  return {
    persistedLocale,
    reloadingAfterLanguageChange: computed(
      () => reloadingAfterLanguageChange.value
    ),
    changeLanguage(newLanguage: string) {
      reloadingAfterLanguageChange.value = false;

      if (serverSideLocale.value !== newLanguage && id.value) {
        reloadingAfterLanguageChange.value = true;
        userService
          .update(id.value, {
            language_id: newLanguage,
          })
          .then(() => {
            persistedLocale.value = newLanguage;
            return router.go(0);
          })
          .finally(() => {
            reloadingAfterLanguageChange.value = false;
          });
      }
    },
    locale: computed<string>(() => serverSideLocale.value),
  };
});
